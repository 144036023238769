import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter05 from '../common/src/assets/image/charity/specter/kapitola_05_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Piata kapitola: Infiltrácia Západu | Ako duch komunizmu vládne nášmu svetu"
           description="Piata kapitola: Infiltrácia Západu"
           image={imageChapter05}/>
      <h1>Piata kapitola: Infiltrácia Západu</h1>
      <h2>Úvod</h2>
      <p>Americké prezidentské voľby roku 2016 patrili medzi najdramatickejšie za posledných niekoľko desaťročí. Volebné
        súboje boli plné prekvapivých zvratov, ktoré pretrvávali aj po voľbách. Víťaz Donald Trump, kandidát za
        Republikánsku stranu, sa ocitol v obkľúčení negatívnej mediálnej publicity a&nbsp;čelil protestom v mestách po
        celej krajine. Demonštranti držali plagáty s nápismi ako „Toto nie je môj prezident!“ a vyhlasovali, že Trump je
        rasista, sexista, xenofób či nacista. Ešte predtým, ako sa ujal vlády, objavili sa požiadavky na opakovanie
        volieb a&nbsp;vyhrážky so snahami o jeho odvolanie.</p>
      <p>Investigatívni novinári odhalili, že mnohé z týchto protestov boli podnietené konkrétnymi záujmovými skupinami.
        Dokumentárny film <em>America Under Siege: Civil War 2017</em> (Amerika v obkľúčení: Občianska vojna 2017)
        Trevora Loudona, politického aktivistu z Floridy, ukazuje, že významnú časť protestujúcich tvorili
        „profesionálni revolucionári“ s väzbami na komunistické a iné totalitné režimy, ako sú Severná Kórea, Irán,
        Venezuela alebo Kuba. Dokument tiež upozornil na úlohu dvoch amerických socialistických organizácií:
        stalinistickej Robotníckej svetovej strany (Workers World Party) a maoistickej Socialistickej organizácie Cesta
        slobody (Freedom Road Socialist Organization).<a href="#_edn1" id="_ednref1">[1]</a></p>
      <p>Loudon, ktorý od 80. rokov skúma komunistické hnutia, zistil, že ľavicové organizácie označili Spojené štáty za
        svoj prvoradý cieľ pre infiltráciu a rozvracanie. Oblasti americkej politiky, vzdelávania, médií a podnikania sa
        pod vplyvom dobre umiestnených jednotlivcov, pôsobiacich na citlivých miestach, presúvajú čoraz viac
        k&nbsp;ľavici. Dokonca aj po studenej vojne, kedy sa ľudia tešili z triumfu slobodného sveta, komunizmus
        ukradomky obsadzoval verejné inštitúcie západnej spoločnosti a pripravoval sa na svoj konečný zápas.</p>
      <p>Komunizmus sa vo východných krajinách, ako je Sovietsky zväz alebo Čína, prejavuje ako totalitná vláda.
        V&nbsp;týchto krajinách dochádza k hromadnému zabíjaniu a ničeniu tradičnej kultúry. Avšak kontrolu nad Západom
        získava potichu a pomaly prostredníctvom podvádzania a dezinformácie. Ničí hospodárstvo, politické procesy,
        sociálne štruktúry a morálnu štruktúru ľudstva, aby spôsobila degeneráciu a zničenie ľudstva.</p>
      <p>Amerika je svetlom slobodného sveta a vykonáva úlohu svetovej polície, ktorú jej zverili nebesá. Bolo to práve
        zapojenie sa Spojených štátov, ktoré nakoniec rozhodlo o výsledku oboch svetových vojen. Počas studenej vojny
        Amerika napriek hrozbe jadrového vyhladenia úspešne krotila sovietsky blok až do rozpadu komunistických režimov
        v Sovietskom zväze a&nbsp;vo východnej Európe. Úspech amerického experimentu so slobodnou a osvietenou vládou
        tak ušetril svet pred väčšou deštrukciou a&nbsp;dominanciou tyranských režimov.</p>
      <p>Otcovia zakladatelia USA pri spisovaní Deklarácie nezávislosti a Ústavy Spojených štátov uplatnili svoje
        vedomosti náboženských a filozofických tradícií Západu. V týchto dokumentoch sa považuje za samozrejmé, že
        človek má práva, ktoré pochádzajú od Boha – počnúc slobodou viery a prejavu – a ustanovili rozdelenie moci,
        ktoré zaručuje republikový systém vlády.</p>
      <p>Sloboda na západe je v&nbsp;priamom protiklade ku cieľu komunizmu. Hoci sa skrýva za nádherné vízie kolektívnej
        a&nbsp;rovnostárskej spoločnosti, jeho cieľom je zotročenie a zničenie ľudstva.</p>
      <p>Keďže komunistické strany nemajú v rukách moc nad západnými krajinami, ich cieľom je dobyť Západ
        prostredníctvom rozvracania, takže ich prívrženci prenikli do všetkých organizácií a inštitúcií. Na Západe
        existuje prinajmenšom päť hlavných síl, ktoré komunistický rozvrat poháňajú.</p>
      <p>Prvou podvratnou silou bol bývalý Sovietsky zväz, ktorý na celosvetové šírenie revolúcie založil komunistickú
        Tretiu internacionálu (Kominternu).</p>
      <p>Druhou podvratnou silou sa stali miestne komunistické strany, ktoré spolupracovali so sovietskou komunistickou
        stranou a Kominternou.</p>
      <p>Treťou podvratnou silou bolo prijatie socialistickej politiky mnohými západnými vládami, ktoré boli zasiahnuté
        ekonomickou krízou a spoločenskými otrasmi posledných desaťročí, čo malo za následok plynulý posun k ľavici.</p>
      <p>Štvrtá podvratná sila vzišla od tých, ktorí podporujú komunistickú stranu a socializmus a ktorí s nimi
        sympatizujú. Títo „spolucestujúci“ slúžia komunizmu v západnej spoločnosti ako piata kolóna tzv. užitočných
        idiotov, ktorí pomáhajú ničiť kultúru, rozsievať morálnu degeneráciu a podrývať legitímnu vládu.</p>
      <p>ČKS je piatou silou. Potom, ako čínski komunisti uskutočnili ekonomickú reformu, ktorá sa začala v 80. rokoch
        20. storočia, nadviazala ČKS politické, obchodné a kultúrne vzťahy, ktoré jej umožnili infiltrovať Západ.</p>
      <p>Vzhľadom na neprehľadnú a spletitú povahu komunistickej infiltrácie na Západe je jej podrobný popis už nad
        rámec tejto práce. Pochopením hrubého náčrtu si však čitatelia môžu urobiť obraz o tom, ako zlo funguje, a
        naučiť sa nazerať pod povrch jeho podvodov. V záujme stručnosti táto kapitola ponúka len všeobecný prehľad o
        rozsahu pôsobenia komunizmu v Spojených štátoch a západnej Európe.</p>
      <h2>1. Násilný a nenásilný komunizmus</h2>
      <p>Vo všeobecnom povedomí je komunistická strana synonymom násilia – a je to tak oprávnene. Avšak skutočnosť, že
        komunistické režimy Ruska a Číny prevzali moc násilnou revolúciou a použili násilie ako nástroj represie,
        odvrátila pozornosť od menej nápadných foriem komunizmu. V Komunistickom manifeste totiž Karl Marx a Friedrich
        Engels doslova uvádzajú: „Komunisti pokladajú za nedôstojné, aby tajili svoje názory a úmysly. Vyhlasujú
        otvorene, že ich ciele možno dosiahnuť len násilným zvrhnutím celého doterajšieho spoločenského poriadku.“<a
          href="#_edn2" id="_ednref2">[2]</a></p>
      <p>Podľa Marxa mala komunistická revolúcia začať vo vyspelých kapitalistických krajinách. Vladimír Lenin však
        veril, že socializmus by mohol byť vybudovaný v Rusku, ktoré bolo v ekonomickom vývoji pomerne pozadu. Leninov
        druhý, významnejší príspevok k marxizmu, bola jeho doktrína budovania strany. Budovanie strany sa v podstate
        skladalo z preberania techník donucovania, klamania a násilia, ktoré nájdeme v zločineckých organizáciách, a tie
        potom boli poháňané marxistickou sociálnoekonomickou teóriou. Podľa Lenina si robotnícka trieda sama osebe nie
        je schopná vyvinúť triedne vedomie, &nbsp;alebo požadovať revolúciu, ale musí byť posunutá k akcii vonkajším
        pôsobením. Činitelia revolúcie sa majú zorganizovať do vysoko disciplinovaného proletárskeho „predvoja“ –
        komunistickej strany.</p>
      <p>Fabiánska spoločnosť, založená v Británii roku 1884, teda rok po smrti Karola Marxa, si pre boj za nastolenie
        socializmu zvolila inú cestu. Fabiáni majú vo svojom znaku vlka v ovčom rúchu a&nbsp;sú pomenovaní po rímskom
        vojvodcovi a diktátorovi Quintusovi Fabiovi Maximovi Verrucosovi, ktorý sa stal známym svojou vyčkávacou
        taktikou v&nbsp;boji s Kartágom. Na titulnej strane prvého pamfletu tejto skupiny sa píše: „Na správnu chvíľu si
        musíš počkať, tak ako to nanajvýš trpezlivo robil Fabius, keď bojoval proti Hannibalovi, hoci mnohí jeho
        otáľanie odsudzovali. Avšak keď príde čas, musíš tvrdo udrieť, práve tak ako Fabius, inak tvoje čakanie bude
        márne a bezvýsledné.“<a href="#_edn3" id="_ednref3">[3]</a></p>
      <p>Fabiánska spoločnosť na postupné zavádzanie socializmu vyvinula stratégiu „prenikania“ a využíva medzery v
        politike, podnikaní a občianskej spoločnosti. Fabiánska spoločnosť povzbudzuje svojich členov, aby propagovali
        socialistické ciele tým, že vstúpia do vhodných organizácií a&nbsp;získajú si priazeň dôležitých osôb, ako sú
        ministri vlád, asistenti vysokých vládnych predstaviteľov, priemyselníci, dekani univerzít alebo vedúci
        predstavitelia cirkví. Sidney Webb, predseda Fabiánskej spoločnosti, k tomu napísal:</p>
      <p><em>Naša spoločnosť dôrazne trvá na tom, že socializmus nie je sekularizmus a vítame podporu mužov i žien z
        každého náboženstva, aj tých bez vyznania. Skutočným predmetom a cieľom všetkých rozumných kolektívnych činností
        bol rozvoj individuálnej duše, alebo svedomia, či charakteru. ... Ani sme neobmedzili našu propagandu na
        pozvoľna sa rozvíjajúcu Stranu práce, na tých, ktorí boli pripravení nazývať sa socialistami, alebo na
        robotníkov, či akúkoľvek konkrétnu triedu. Predkladáme naše návrhy jeden po druhom, tak presvedčivo, ako len to
        je možné, všetkým, ktorí ich sú ochotní načúvať – ​​konzervatívcom, keď sme sa k nim dokázali dostať, cirkvám a
        kostolom všetkých náboženstiev, rozličným univerzitám, liberálom aj radikálom, spolu s inými socialistickými
        spolkami, vždy keď to je možné. Nazvali sme to „prenikanie“ – a bol to dôležitý objav.</em><a href="#_edn4"
                                                                                                      id="_ednref4"><em>[4]</em></a>
      </p>
      <p>Či už je to Leninov násilný komunizmus alebo nenásilný komunizmus Fabiánskej spoločnosti, oba majú ten istý
        konečný cieľ. Leninov násilný komunizmus však neodmieta nenásilné prostriedky. Vo svojej knihe <em>Detská nemoc
          „ľavičiarstva“ v komunizme</em> kritizoval Lenin komunistické strany západnej Európy, ktoré odmietli
        spolupracovať s tými, koho on označil za „reakcionárske“ robotnícke odbory, alebo sa pripojiť ku
        „kapitalistickému“ národnému parlamentu. Vo svojej knihe Lenin napísal: „Pre komunistu správne chápanie
        vlastných cieľov spočíva práve v správnom odhadnutí podmienok a chvíle, kedy predvoj proletariátu môže úspešne
        prevziať moc. Musí sa rozhodnúť, kedy bude tento predvoj schopný získať adekvátnu podporu od dostatočne
        obsiahlych vrstiev robotníckej triedy aj neproletárskych pracujúcich más, a kedy po prevzatí moci dokáže
        udržiavať, upevňovať a rozširovať svoju vládu tým, že bude vychovávať, vzdelávať a získavať stále širšie masy
        pracujúcich.“<a href="#_edn5" id="_ednref5">[5]</a></p>
      <p>Lenin neustále zdôrazňoval, že komunisti musia svoje pravé zámery skrývať. V&nbsp;snahe o uchopenie moci neboli
        vylúčené žiadne sľuby či kompromisy. Inými slovami, aby komunisti dosiahli svoje ciele, musia byť
        bezcharakterní. Ruskí boľševici aj čínske komunistické hnutie v maximálnej miere svojvoľne používali násilie a
        podvod.</p>
      <p>Krutosť komunistických režimov v Sovietskom zväze a v Číne odvrátila pozornosť od nenásilného komunizmu na
        Západe. Fabiánska spoločnosť sa špecializovala na maskovanie. Vybrali si Bernarda Shawa, írskeho dramatika, aby
        zakryl skutočné ciele nenásilného socializmu prozaickou rétorikou. Brutalita však zostáva pod povrchom. Shaw
        napísal na poslednej strane svojej knihy <em>Príručka inteligentnej ženy k socializmu a kapitalizmu</em>: „Tiež
        som jasne objasnil, že socializmus znamená rovnakú mzdu alebo nič, a že za&nbsp;socializmu nebude
        možné&nbsp; byť chudobným. Budete násilne kŕmený, ošatený, ubytovaný, učený a zamestnaný, či už sa vám to páči
        alebo nie. Ak by sa zistilo, že nemáte dostatočný charakter na to, aby ste toho všetkého boli hodný, môžete byť
        láskavým spôsobom popravený.“<a href="#_edn6" id="_ednref6">[6]</a></p>
      <p>Rovnako ako pre leniský režim niekedy môže byť užitočné zmierniť zjavnú brutalitu svojej vlády, západné
        komunistické strany a ich rôzne frontové organizácie nevykonávajú násilie a iné trestné činy, čím rozširujú svoj
        politický program.</p>
      <h2>2. Špionážna a dezinformačná vojna</h2>
      <p>Komunisti zastávajú názor, že národ je utláčajúci konštrukt triednej spoločnosti, a preto sa usilujú odstrániť
        koncepty patriotizmu a národnej lojality. V <em>Komunistickom manifeste</em> Marx a Engels vyhlasujú, že
        „robotník nemá žiadnu krajinu“. Manifest končí poznámkou: „Proletári všetkých krajín, spojte sa!“</p>
      <p>Pod Leninovým vedením založili boľševici v Rusku prvý socialistický štát a bezodkladne zriadili Komunistickú
        internacionálu (tzv. Kominternu) za účelom podnecovania a šírenie socialistickej revolúcie po celej zemeguli.
        Cieľom Sovietskeho zväzu a Kominterny bolo zvrhnúť legitímne režimy všetkých národov na zemi a zriadiť
        celosvetovú socialistickú diktatúru proletariátu.</p>
      <p>Komunistické strany celého sveta vyhľadávali vedenie Kominterny a prijímali od nej financovania a školenia. So
        zdrojmi ohromnej ​​ríše, akou bol Sovietsky zväz, robili boľševici nábor aktivistov z celého sveta a školili
        ich, aby vykonávali podvratné operácie vo svojich krajinách.</p>
      <p>Komunistická strana USA, založená v roku 1919, bola jednou týchto organizácií, ktoré nasledovali Kominternu a
        KSSZ. Komunistická strana USA sa nikdy nestala hlavnou politickou silou, avšak jej vplyv na Spojené štáty bol
        napriek tomu významný. Dohodla sa s aktivistami a ich organizáciami na infiltrovaní robotníckych a študentských
        hnutí, cirkví a vlády.</p>
      <p>Priekopník amerického antikomunistického myslenia, Fred Schwartz, v roku 1961 povedal Výboru pre Ne-americké
        aktivity Snemovne reprezentantov: „Akýkoľvek pokus posúdiť vplyv komunistov podľa ich počtu je ako pokúsiť sa
        stanoviť spoľahlivosť lodného trupu porovnávaním deravých miest s neporušenou plochou. Jedna diera môže potopiť
        loď. Komunizmus je teória zopár disciplinovaných, ktorí ovládajú a vedú zvyšok. Jedna osoba na citlivom mieste
        môže ovládať a manipulovať tisíce iných.“<a href="#_edn7" id="_ednref7">[7]</a></p>
      <p>Dnes už vieme, že počas druhej svetovej vojny boli vnútri americkej vlády aktívni sovietski tajní agenti.
        Napriek tejto skutočnosti a napriek antikomunistickým snahám senátora Josepha McCarthyho boli ľavicovými
        politikmi, akademikmi a ľavicovými médiami tieto fakty pred verejnosťou utajené či zakryté.</p>
      <p>V 90. rokoch 20. storočia americká vláda sprístupnila „Venonské spisy“, zbierku sovietskych správ dešifrovaných
        americkou rozviedkou počas druhej svetovej vojny. Tieto dokumenty ukazujú, že v americkej vláde pracovalo
        minimálne 300 sovietskych špiónov, a boli dokonca aj medzi vysoko postavenými úradníkmi Rooseveltovej vlády,
        ktorí mali prístup k prísne tajným informáciám. Ďalší agenti využívali svoje pozície na ovplyvnenie amerického
        zákonodarstva a štátnictva. Medzi tými, ktorí boli odhalení ako sovietski špióni, boli úradník amerického
        ministerstva financií Harry Dexter White, úradník ministerstva zahraničia Alger Hiss a manželia Julius a Ethel
        Rosenbergovi, ktorí boli popravení na elektrickom kresle za vynášanie armádnych tajomstiev a atómových
        technológií do Sovietskeho zväzu.</p>
      <p>Komunikácie zachytené a dešifrované projektom Venona sú len špičkou ľadovca. Plný rozsah sovietskej infiltrácie
        v americkej vláde zostáva neznámy. Niektorí z pôsobiacich sovietskych agentov mali ako vysoko postavení americkí
        úradníci príležitosť ovplyvniť dôležité politické rozhodnutia.</p>
      <p>Alger Hiss, ktorý pôsobil ako riaditeľ Úradu špeciálnych politických záležitostí ministerstva zahraničných
        vecí, zohral kľúčovú úlohu ako poradca prezidenta Franklina D. Roosevelta počas konferencie v Jalte na konci
        druhej svetovej vojny. Pomohol určiť povojnové teritoriálne usporiadanie, navrhnúť Chartu OSN, rozhodnúť o
        výmenách zajatcov a podobne.</p>
      <p>Harry Dexter White, dôveryhodný asistent ministra financií Henriho Morgenthaua ml., pomáhal vytvoriť
        medzinárodnú finančnú dohodu z Bretton Woods z roku 1944 a bol jednou zo zakladajúcich osobností Medzinárodného
        menového fondu a Svetovej banky. White odporučil Čínskej národnej strane (Kuomintang), aby zamestnala tajného
        člena ČKS Ťi Čao-tinga na čínskom ministerstve financií. Keď potom Ťi v roku 1941 nastúpil na svoju pozíciu,
        stal sa strojcom katastrofických menových reforiem, ktoré poškodili povesť Kuomintangu a prispeli ku vzostupu
        ČKS.<a href="#_edn8" id="_ednref8">[8]</a> Niektorí historici sa domnievajú, že vplyv sovietskych špiónov a ich
        ľavicových sympatizantov v americkej zahraničnej politike viedol Spojené štáty k ukončeniu vojenskej pomoci
        Kuomintangu počas čínskej občianskej vojny, ktorá nasledovala po druhej svetovej vojne. Pevninská Čína v
        dôsledku toho upadla do rúk komunistickej strany.<a href="#_edn9" id="_ednref9">[9]</a></p>
      <p>Whittaker Chambers, sovietsky informátor a spolupracovník Komunistickej strany USA, ktorý neskôr dezertoval a
        svedčil proti ostatným špiónom, vypovedal: „Agenti nepriateľskej mocnosti boli v pozícii, kedy mohli robiť oveľa
        viac, než len kradnúť dokumenty. Boli schopní ovplyvniť zahraničnú politiku národa v záujme jeho úhlavného
        nepriateľa. A to nielen pri výnimočných príležitostiach, ... ale v&nbsp;tom, čo muselo byť ohromujúcim súčtom
        každodenných rozhodnutí.“<a href="#_edn10" id="_ednref10">[10]</a></p>
      <p>Jurij Bezmenov, agent KGB, ktorý v roku 1970 dezertoval na Západ, rozoberal sovietske metódy rozvracania vo
        svojich spisoch a rozhovoroch. Špióni, tak ako ich popisuje populárna kultúra, v&nbsp;ktorej James
        Bond &nbsp;vyhadzuje do vzduchu mosty &nbsp;a potajomky sa zakráda s ukradnutými prísne tajnými dokumentmi, sa
        podľa Bezmenova veľmi líšia&nbsp; od tých skutočných. Na tradičné špionážne operácie bolo pridelených iba 10 až
        15 % zamestnancov a zdrojov KGB, zvyšok pracoval na ideologickom rozvracaní.</p>
      <p>Bezmenov poznamenal, že podvratná činnosť prebieha v štyroch štádiách: demoralizácia, destabilizácia, kríza, a
        „normalizácia“. Prvým krokom, trvajúcim asi jednu generáciu, je zameriavať sa na demoralizáciu nepriateľskej
        krajiny; druhým je vytvoriť spoločenský chaos; tretím je podnietiť krízu, ktorá prejde do občianskej vojny,
        revolúcie alebo invázie z inej krajiny, čo vyvrcholí vo štvrtom, poslednom štádiu „normalizácie“ – čo je
        ovládnutie krajiny komunistickou stranou.</p>
      <p>Bezmenov, alias Thomas Schumann, vymenoval tri oblasti rozvracania alebo demoralizácie v&nbsp;prvom kroku,
        ktoré zahŕňali myšlienkovú oblasť, mocenskú oblasť a oblasť spoločenského života. Myšlienková oblasť pokrýva
        náboženstvo, vzdelávanie, médiá a kultúru. Do mocenskej oblasti sa počíta vládna administratíva, právny systém,
        vymáhanie práva, ozbrojené sily a diplomacia. Oblasť spoločenského života obsahuje rodiny a komunity, zdravie a
        vzťahy medzi odlišnými rasami a sociálnymi triedami.</p>
      <p>Ako príklad Bezmenov vysvetlil zmanipulovanie konceptu rovnosti, aby sa vytvorili nepokoje. Agenti propagovali
        rovnostárstvo, čo vyústi do toho, že ľudia sa cítia nespokojní so svojimi politickými a ekonomickými pomermi.
        Aktivizmus a občiansky nepokoj spôsobia, že ekonomika uviazne na mŕtvom bode, čo ďalej zhorší pracovné i
        kapitálové vzťahy v zostupnom cykle destabilizácie. To vyvrcholí revolúciou alebo inváziou komunistických síl.<a
          href="#_edn11" id="_ednref11">[11]</a></p>
      <p>V roku 1978 dezertoval do Spojených štátov Ion Mihai Pacepa, najvyššie postavený úradník rozviedky
        komunistického Rumunska. Odhalil stratégie, ktoré použili komunistické režimy v rámci vedenia psychologickej a
        dezinformačnej vojny proti západným krajinám. Podľa Pacepu bolo účelom dezinformácií zmeniť u ľudí referenčný
        rámec vnímania. Ak by došlo ku zmanipulovaniu ideových hodnôt ľudí, neboli by potom schopní pochopiť alebo
        akceptovať pravdu, ani keby bola podložená priamym dôkazom.<a href="#_edn12" id="_ednref12">[12]</a></p>
      <p>Bezmenov povedal, že prvá fáza ideologického rozvratu zvyčajne trvá 15 až 20 rokov, čo je čas potrebný na
        vychovanie nasledujúcej generácie. Druhá fáza trvá 2 až 5 rokov. Tretia fáza len 2 až 6 mesiacov. V príhovore,
        ktorý predniesol roku 1984, Bezmenov uviedol, že prvá fáza bola vykonaná vo väčšom rozsahu, než sovietske
        riadiace orgány pôvodne očakávali.</p>
      <p>Výpovede mnohých sovietskych špiónov a pracovníkov rozviedky spoločne so sprístupnenými dokumentmi zo studenej
        vojny odhaľujú, že taktika infiltrácie a rozvracania bola hybnou silou, ktorá poháňala hnutie kontrakultúry v
        60. rokoch.</p>
      <p>V roku 1950 začal senátor Joseph McCarthy odhaľovať rozsah komunistickej infiltrácie naprieč americkou vládou a
        spoločnosťou. O štyri roky neskôr však senát odhlasoval jeho napomenutie, a tak bola zastavená snaha, ktorú
        vláda podnikala na svojej ceste k vymaneniu sa spod komunistického vplyvu. McCarthyizmus je dnes synonymom
        politického prenasledovania – náznak toho, že ľavica si úspešne ustanovila dominantné postavenie v ideologickom
        boji.</p>
      <p>Hrozba komunistickej infiltrácie sa s kolapsom Sovietskeho zväzu a&nbsp;s koncom studenej vojny nezmenšila.
        Ľavica zubami nechtami bojuje o to, aby ochránila cudzoložníkov, obhajcov potratov, zločincov a komunistov, a
        zároveň podporuje anarchiu a stavia sa proti civilizácii.</p>
      <h2>3. Od „Novej dohody“ k progresivizmu</h2>
      <p>24. októbra 1929 došlo k panike na newyorskej burze cenných papierov, keď bolo ponúknutých na predaj rekordných
        12,9 miliónov akcií. Kríza sa z finančného systému rozšírila na celú ekonomiku a následná Veľká hospodárska
        kríza neušetrila žiadnu z rozvinutých krajín sveta. Miera nezamestnanosti, ktorá bola v roku 1929 v USA 3 %, do
        roku 1933 vzrástla na jednu štvrtinu pracovnej sily. Priemyselná produkcia v hlavných priemyselných krajinách s
        výnimkou Sovietskeho zväzu klesla v priemere o 27%.<a href="#_edn13" id="_ednref13">[13]</a></p>
      <p>Začiatkom roka 1933, v&nbsp;priebehu prvých 100 dní od Rooseveltovej inaugurácie, boli zavedené mnohé opatrenia
        na riešenie krízy. Politici&nbsp; posilnili vládne intervencie do ekonomiky a presadili niektoré hlavné reformy.
        Kongres prijal Zákon o núdzovom bankovníctve, Zákon o poľnohospodárskych úpravách, Národný zákon o priemyselnej
        obnove a Zákon o sociálnom zabezpečení. Hoci vypuknutím druhej svetovej vojny Rooseveltova „Nová dohoda“ v
        zásade skončila, mnoho inštitúcií a organizácií, ktoré vznikli počas toho obdobia, pokračovalo vo formovaní
        americkej spoločnosti a pôsobí dodnes.</p>
      <p>Roosevelt vydal viac výkonných nariadení ako všetci prezidenti 20. storočia dohromady. Napriek tomu miera
        nezamestnanosti v USA v čase vojny až do roku 1941 neklesla pod dvojciferné číslo. Skutočným dôsledkom
        „Novej&nbsp; dohody“ pre vládu Spojených štátov bolo jej privedenie na cestu vysokého zdanenia, prebujneného
        vládneho aparátu a ekonomického intervencionizmu.</p>
      <p>Vo svojej knihe z roku 2017 <em>The Big Lie: Exposing the Nazi Roots of the American Left</em> (Veľká lož:
        Odhalenie nacistických koreňov americkej ľavice) konzervatívny mysliteľ Dinesh D'Souza uvádza, že Zákon o
        národnej priemyselnej obnove, ktorý tvoril ústrednú časť Rooseveltovej Novej dohody, v podstate znamenal koniec
        slobodného amerického trhu.<a href="#_edn14" id="_ednref14">[14]</a></p>
      <p>Podľa knihy historika Jima Powella <em>FDR 's Folly</em> (Pochabosť FDR) z roku 2003 tzv. Nová dohoda Veľkú
        hospodársku krízu skôr predĺžila, než aby ju ukončila: Zákon o sociálnom zabezpečení a zákony práce ešte viac
        prehĺbili nezamestnanosť, zatiaľ čo vysoké dane zaťažili zdravé podnikanie atď.<a href="#_edn15"
                                                                                          id="_ednref15">[15]</a> Ekonóm
        a nositeľ Nobelovej ceny Milton Friedman vyzdvihol Powellovu prácu týmito slovami: „Powell bez najmenších
        pochybností ukazuje, že Nová dohoda sťažila obnovu síl, predĺžila a zvýšila nezamestnanosť a pripravila pôdu pre
        ešte neodbytnejšiu a nákladnejšiu vládu.“<a href="#_edn16" id="_ednref16">[16]</a></p>
      <p>Prezident Lyndon Johnson, ktorý nastúpil do funkcie v roku 1963 po atentáte na prezidenta Kennedyho, vo svojej
        Správe o stave únie vyhlásil „vojnu proti chudobe a nezamestnanosti“ a spustil vnútroštátne sociálne programy
        tzv. Veľkej spoločnosti (Great Society). Krátko nato Johnson vydal sériu výkonných nariadení, založil nové
        vládne agentúry, posilnil sociálny štát, zvýšil dane a dramaticky rozšíril právomoci vlády.</p>
      <p>Je zaujímavé sledovať podobnosti medzi administratívnymi opatreniami prezidenta Johnsona a cieľmi zhrnutými v
        tom období v komunistických spisoch. Generálny tajomník Komunistickej strany USA Gus Hall vyhlásil:
        „Komunistický postoj k Veľkej spoločnosti môžeme zhrnúť starým príslovím, že dvaja ľudia spiaci v rovnakej
        posteli, môžu mať rozdielne sny... My podporujeme tieto opatrenia, pretože snívame o socializme.“</p>
      <p>Iniciatívu Veľkej spoločnosti podporovala aj Komunistická strana USA, ale jej zámer sa líšil od zámeru
        Johnsona. Zámerom Johnsonovej vlády bolo pomôcť Spojeným štátom v rámci demokratického systému. Zámerom
        komunistickej strany bolo naproti tomu postupnými krokmi vmanévrovať Spojené štáty do socializmu.</p>
      <p>Najzávažnejšie dôsledky „Veľkej spoločnosti“ a „vojny proti chudobe“ spočívali v tom, že zvýšili závislosť ľudí
        na sociálnych dávkach, odradili ľudí od práce a poškodili štruktúru rodiny. Politika sociálneho zabezpečenia
        zvýhodňovala rodiny s jedným rodičom, čo podporilo rozvodovosť a zvýšilo počet nemanželských detí. Podľa
        štatistík tvoril počet detí narodených mimo manželstva v roku 1940 3,8 % všetkých novorodencov. Do roku 1965
        toto číslo vzrástlo na 7,7 %. V roku 1990, čo bolo 25 rokov po&nbsp; Veľkej sociálnej reforme, dosahovalo toto
        číslo už 28 % a v roku 2012 opäť vzrástlo až na 40%.<a href="#_edn17" id="_ednref17">[17]</a> Táto politika
        neúmerne ovplyvnila menšiny, najmä Afroameričanov.</p>
      <p>Rozpad rodín so sebou priniesol ďalekosiahle následky v&nbsp;podobe zvýšenia generačnej chudoby, prudko
        stúpajúcej kriminality, ústupu od rodičovského vzdelávania, a mentality nárokov na sociálne dávky, ktoré viedli
        k vyššej miere dobrovoľnej nezamestnanosti.</p>
      <p>Situáciu vystihuje citát, ktorý sa pripisuje škótskemu historikovi a právnikovi, lordovi Alexanderovi Fraserovi
        Tytlerovi: „Demokracia nemôže existovať ako trvalá forma vlády. Môže existovať len do tej doby, než voliči
        zistia, že si môžu sami zvoliť, aká štedrá bude ich štátna pokladnica. Od tej chvíle si väčšina vždy zvolí
        kandidáta sľubujúceho najviac príspevkov zo štátnej pokladnice. To má za následok, že demokracia sa vždy zrúti
        kvôli voľnej fiškálnej politike a po nej potom vždy nasleduje diktatúra a monarchia.“<a href="#_edn18"
                                                                                                id="_ednref18">[18]</a>
      </p>
      <p>Ako hovorí jedno čínske príslovie: „Cesta od šetrnosti k márnotratnosti je jednoduchá, ale opačne už nie.“
        Potom, čo si ľudia vyvinú závislosť na sociálnych dávkach, vláda už nedokáže zredukovať škálu a druhy prídavkov.
        Západný sociálny štát sa stal politickým močiarom, pre ktorý politici a úradníci nemajú žiadne riešenie.</p>
      <p>V 70. rokoch sa krajná ľavica vzdala revolučných termínov, pred ktorými sa mali americkí občania na pozore, a
        nahradila ich neutrálnejšie znejúcimi termínmi, ako „liberalizmus“ a „progresivizmus“ („pokrokovosť“).
        Čitateľom, ktorí žili v komunistických krajinách, nie je druhý termín cudzí, pretože slovo „pokrok“ komunistická
        strana používala viac-menej ako synonymum pre „komunizmus“. Napríklad termín „pokrokové hnutie“ označoval
        „komunistické hnutie“ a „pokrokoví intelektuáli“ boli „prokomunistickí jednotlivci“ alebo neoficiálne členovia
        komunistickej strany.</p>
      <p>Liberalizmus sa v zásade príliš nelíši od progresivizmu, pretože so sebou nesie rovnakú konotáciu v podobe
        vysokých daní, rozšírených sociálnych dávok, veľkej vlády, odmietania náboženstva, morálky a tradícií,
        zneužívanie „politickej korektnosti“ a „sociálnej spravodlivosti“ ako politických zbraní a militantnú propagáciu
        feminizmu, homosexuality, sexuálnej perverznosti a podobne.</p>
      <p>Nie je naším cieľom ukazovať prstom na nejakú konkrétnu politickú postavu alebo jednotlivca, pretože je vskutku
        ťažké správne analyzovať a posúdiť zložitý historický vývoj. Je zrejmé, že duch komunizmu pôsobil na Východe i
        na Západe už od začiatku 20. storočia. Hneď ako násilná revolúcia uspela na Východe, šírila vplyv komunizmu do
        vlád a spoločností na Západe a posúvala ich stále viac doľava.</p>
      <p>Po veľkej hospodárskej kríze Spojené štáty čoraz viac prijímali socialistickú politiku, napríklad v podobe
        štátnej sociálnej podpory, zatiaľ čo ateizmus a materializmus postupne rozkladali morálnu štruktúru americkej
        spoločnosti. Ľudia sa postupne odcudzili Bohu a tradičnej morálke, čo oslabilo ich odolnosť voči klamstvám.</p>
      <h2>4. Kultúrna revolúcia na Západe</h2>
      <p>60. roky 20. storočia, predelový okamih novodobej histórie, boli svedkom bezprecedentnej vlny kontrakultúry,
        ktorá drasticky prenikala z Východu na Západ. Na rozdiel od kultúrnej revolúcie čínskych komunistov sa západné
        hnutie kontrakultúry javilo chaoticky, pretože sa zameriavalo na rôzne ciele, či lepšie povedané, nemalo žiaden
        konkrétny cieľ.</p>
      <p>Prevažne mladí účastníci hnutia proti kultúre boli v priebehu 60. a 70. rokov motivovaní honbou za rôznymi
        ideálmi. Niektorí boli proti vojne vo Vietname, niektorí bojovali za občianske práva, niektorí obhajovali
        feminizmus a odsúdili patriarchát a ešte iní usilovali o práva homosexuálov. Všetko završovala oslnivá plejáda
        hnutí proti tradíciám a autoritám, ktoré obhajovali sexuálnu slobodu, hedonizmus, drogy a rockovú hudbu.</p>
      <p>Cieľom tejto západnej kultúrnej revolúcie je zničiť kresťanskú civilizáciu a jej tradičnú kultúru. Hoci sa javí
        chaoticky a neusporiadane, tento medzinárodný kultúrny posun má korene v komunizme. Mladí účastníci hnutia
        kontrakultúry vyznávali takzvané „Tri M“ – Marxa, Marcusa a Mao Ce-tunga.</p>
      <p>Herbert Marcuse bol kľúčovým členom takzvanej Frankfurtskej školy, skupiny marxistických intelektuálov
        pridružených k Ústavu pre sociálny výskum na Goetheho univerzite vo Frankfurte. Frankfurtská škola vznikla v
        roku 1923 a jej zakladatelia používali koncept tzv. „kritickej teórie“, aby zaútočili na západnú civilizáciu a
        realizovali marxizmus v kultúrnej sfére.</p>
      <p>Jedným zo zakladateľov tejto školy bol maďarský marxista György Lukács.&nbsp; Uviedol, že cieľom vyučovania
        bolo odpovedať na otázku: „Kto nás môže zachrániť pred západnou civilizáciou?“<a href="#_edn19"
                                                                                         id="_ednref19">[19]</a> Túto
        otázku ďalej podporil vyhlásením, že Západ je vinný zo zločinov genocídy voči každej civilizácii a kultúre, na
        ktorú narazil. Americká a západná civilizácia sú podľa Lukácsa najväčším zdrojom rasizmu, sexizmu, politického
        nativizmu, xenofóbie, antisemitizmu, fašizmu a narcizmu na svete.</p>
      <p>V roku 1935 sa marxisti z Frankfurtskej školy presťahovali do Spojených štátov a pridružili sa ku Kolumbijskej
        univerzite v New Yorku. To im umožnilo šíriť svoje teórie na americkej pôde. Za pomoci ďalších ľavicových
        intelektuálov pokazili niekoľko generácií americkej mládeže.</p>
      <p>Marcuseove teórie, ktoré kombinujú marxizmus s Freudovým pansexualizmom, boli katalyzátorom hnutia za sexuálnu
        slobodu. Marcuse veril, že potláčanie vlastnej povahy v kapitalistickej spoločnosti bráni oslobodeniu a slobode.
        Preto bolo nevyhnutné vzdorovať všetkým tradičným náboženstvám, morálke, poriadku a autoritám, aby sme mohli
        transformovať spoločnosť na utópiu bezmedzného potešenia bez námahy.</p>
      <p>Marcuseova slávna práca z roku 1955 <em>Eros a civilizácia: Filozofické skúmanie vo Freudovi </em>zastáva
        významné miesto medzi širokým počtom diel Frankfurtskej školy z dvoch dôvodov. Po prvé, kniha spája myšlienky
        Marxa a Freuda a pretvára Marxovu kritiku politiky a ekonómie do kritiky kultúry a psychológie. Po druhé, kniha
        postavila most od frankfurtských teoretikov k mladým čitateľom, čím umožnila kultúrnu vzburu 60. rokov.</p>
      <p>Marcuse uviedol, že protikultúrne hnutie môžeme nazvať „kultúrnou revolúciou, pretože protest sa zameriava na
        celé kultúrne zriadenie, vrátane morálky existujúcej spoločnosti.“ Pokračoval: „Jednu vec môžeme povedať s
        úplnou istotou: tradičná predstava revolúcie a tradičná revolučná stratégia patria minulosti. Tieto myšlienky už
        sú zastarané. ... To, čo musíme podniknúť my, je postupné rozloženie systému.“<a href="#_edn20"
                                                                                         id="_ednref20">[20]</a></p>
      <p>Len hŕstka rebelujúcej mládeže bola schopná pochopiť tajomné teórie Frankfurtskej školy, avšak Marcuseove
        myšlienky boli jednoduché: buďte proti tradíciám, proti autoritám a proti morálke. Bez zábran sa oddávajte sexu,
        drogám a&nbsp;rock and rollu. Dokonca vymyslel frázu „Milujte sa a nebojujte.“ Kým človek odporoval všetkým
        autoritám a spoločenským normám, bol považovaný za účastníka „vznešenej“ revolúcie. Stať sa revolucionárom bolo
        veľmi jednoduché a ľahké. Niet divu, že to v tej dobe priťahovalo toľko mladých ľudí.</p>
      <p>Je potrebné zdôrazniť, že hoci mnohí z rebelujúcich mladých ľudí jednali z&nbsp;vlastnej vôle, mnohí z
        najradikálnejších študentských vodcov hnutia boli vyškolení a riadení zahraničnými komunistami. Napríklad
        vodcovia americkej študentskej aktivistickej organizácie Študenti za demokratickú spoločnosť (SDS) boli podľa
        správ FBI vyškolení kubánskymi špionážnymi agentmi.</p>
      <p>Protivojnové študentské protesty boli priamo organizované a podnietené komunistickými skupinami. Od Študentov
        za demokratickú spoločnosť sa oddelila extrémne ľavicová organizácia Weatherman, ktorá sa objavila, keď sa SDS
        rozpadla v roku 1969.</p>
      <p>Vo vyhlásení z tohto roku Weatherman uvádza citát: „Rozpor medzi revolučnými ľuďmi z Ázie, Afriky a Latinskej
        Ameriky a imperialistami na čele so Spojenými štátmi je zásadným rozporom súčasného sveta. Vývoj tohto rozporu
        podporuje boj ľudí celého sveta proti americkému imperializmu a jeho lokajom.“</p>
      <p>Tieto slová napísal Lin Piao, vtedajší druhý najmocnejší vodca komunistickej Číny a pochádzajú z jeho série
        článkov „Nech žije víťazstvo ľudovej vojny!“.<a href="#_edn21" id="_ednref21">[21]</a></p>
      <p>Tak ako kultúrna revolúcia priniesla nenávratné škody tradičnej čínskej kultúre, hnutie kontrakultúry spôsobilo
        kolosálny prevrat v západnej spoločnosti. Po prvé, znormalizovalo veľa subkultúr, ktoré pôvodne patrili spodnému
        okraju spoločnosti alebo boli deviantnými variáciami hlavného prúdu kultúry. Sexuálne oslobodenie, drogy
        a&nbsp;rock and roll rýchlo rozkladali morálne hodnoty mládeže a premieňali ju na ničivú silu, ktorá sa stavala
        proti Bohu, tradíciám a spoločnosti.</p>
      <p>Po druhé, hnutie kontrakultúry vytvorilo precedens pre chaotický aktivizmus a podporilo širokú škálu
        protispoločenských a protiamerických spôsobov myslenia, ktoré pripravili pôdu pre revolúciu v uliciach, ktorá
        mala prísť neskôr.</p>
      <p>Po tretie, potom, čo mladí ľudia 60. rokov skončili s aktivistickým životným štýlom, nastúpili na univerzity a
        výskumné inštitúty, obhájili svoje doktoráty a magisterské tituly a vstúpili do hlavného prúdu americkej
        spoločnosti. Priniesli si so sebou marxistické videnie sveta a jeho hodnoty a vniesli ich do vzdelávania, médií,
        politiky aj podnikania, čím podporovali nenásilnú revolúciu po celej krajine.</p>
      <p>Od 80. rokov ľavica z veľkej časti obsadila mainstreamové médiá, akademický svet a Hollywood, a urobila si z
        nich svoje pevnosti. Prezident Ronald Reagan tento trend nakrátko zvrátil, ale v 90. rokoch sa tento trend znovu
        vynoril a v nedávnych rokoch dosiahol svoj vrchol.</p>
      <h2>5. Protivojnové hnutie a hnutia za občianske práva</h2>
      <p>V románe Georga Orwella <em>Tisíc deväťsto osemdesiatštyri</em> sa jedno zo štyroch hlavných ministerstiev
        Oceánie volá Ministerstvo mieru a dohliada na vojenské záležitosti strany. Prevrátený význam názvu poukazuje na
        stratégiu, ktorú používajú komunisti: Keď je niečia sila menšia ako sila nepriateľa, je najlepšou stratégiou
        vyhlasovať svoju túžbu po mieri. Oháňať sa olivovou ratolesťou je najlepší spôsob, ako zakryť bezprostredný
        útok.</p>
      <p>Sovietsky zväz a ďalšie komunistické krajiny boli a sú zbehlými vykonávateľmi tejto stratégie, ktorú uplatňujú
        pri infiltrovaní a&nbsp;odzbrojení Západu. Hneď po skončení druhej svetovej vojny boli Spojené štáty ešte stále
        jedinou krajinou na svete, ktorá vyrobila a odskúšala atómovú bombu. Vtedy vznikla Svetová rada mieru. Jej prvým
        predsedom bol francúzsky fyzik Joliot-Curie, člen francúzskej komunistickej strany.</p>
      <p>Potom, čo Sovietsky zväz utrpel vo vojne obrovské straty, agresívne propagoval svetový mier ako lesť, ktorou
        chcel odvrátiť tlak zo Západu. Svetová rada mieru bola priamo riadená Sovietskou komisiou pre obranu a mier, čo
        bola organizácia pridružená k sovietskej komunistickej strane. Táto komisia po celom svete šírila príbeh o tom,
        že Sovietsky zväz je mierumilovnou krajinou, a odsudzovala Spojené štáty ako hegemonického vojnového štváča.
        „Boj za mier“, fráza podporovaná vysokopostaveným sovietskym predstaviteľom a ideologickým vodcom Michailom
        Suslovom, sa stala inventárom sovietskej rétoriky.</p>
      <p>„Súčasné protivojnové hnutie svedčí o vôli a pripravenosti najširších más na zabezpečenie mieru a bráni
        agresorom, aby strhli ľudstvo do priepasti ďalšieho zabíjania,“ napísal Suslov vo svojom propagandistickom
        traktáte z roku 1950. „Teraz je úlohou premeniť túto vôľu más do aktívnych, konkrétnych činov na zmarenie plánov
        a krokov anglo-amerických vojnových štváčov.“<a href="#_edn22" id="_ednref22">[22]</a></p>
      <p>„Svetový mier“ stál v predných líniách komunistickej vojny o verejnú mienku namierenej proti slobodnému svetu.
        Sovietsky zväz sponzoroval množstvo organizácií a skupín na presadzovanie komunistických cieľov, ako napríklad
        Svetovú federáciu odborových zväzov, Svetový zväz mládeže, Medzinárodnú federáciu žien, Medzinárodnú federáciu
        novinárov, Svetový zväz demokratickej mládeže, Svetový zväz vedcov a podobne.</p>
      <p>Vladimir Bukovskij, popredný sovietsky disident, napísal roku 1982, že „členovia staršej generácie si stále
        ešte pamätajú pochody, manifestácie a petície 50. rokov ... Dnes už nie je žiadnym tajomstvom, že celá kampaň
        bola organizovaná, riadená a financovaná z Moskvy cez takzvaný Mierový fond a Sovietmi ovládanú Svetovú radu
        mieru.“<a href="#_edn23" id="_ednref23">[23]</a></p>
      <p>Generálny tajomník Komunistickej strany USA Gus Hall vyhlásil: „Je potrebné rozširovať boj za mier, stupňovať
        ho, zapojiť do neho viac ľudí a urobiť z neho horúcu tému v každej komunite, každej skupine ľudí, každom
        odborovom zväze, každom kostole, každej rodine, na každej ulici a na každom mieste, kde sa ľudia schádzajú.“<a
          href="#_edn24" id="_ednref24">[24]</a></p>
      <p>Sovieti v priebehu studenej vojny pretláčali hnutie „boja za mier“ v troch vlnách. Prvá vlna začala v 50.
        rokoch. Druhá vlna vyvrcholila protivojnovým hnutím 60. a 70. rokov. Stanislav Lunev, bývalý úradník sovietskej
        vojenskej rozviedky GRU, ktorý v roku 1992 dezertoval z Ruska do Spojených štátov, povedal, že „GRU a KGB
        financovali takmer všetky protivojnové hnutia a skupiny v Spojených štátoch a v zahraničí.“<a href="#_edn25"
                                                                                                      id="_ednref25">[25]</a>
      </p>
      <p>Ronald Radosh, bývalý marxista a aktivista počas hnutia proti vojne vo Vietname, pripustil: „Našim cieľom nikdy
        až tak nebolo ukončenie vojny, ako skôr použitie protivojnového sentimentu na vytvorenie nového revolučného
        socialistického hnutia doma [v USA]“.<a href="#_edn26" id="_ednref26">[26]</a></p>
      <p>Tretie dôležité protivojnové hnutie podporované Sovietmi sa odohralo na začiatku 80. rokov, keď Spojené štáty v
        Európe rozmiestnili nukleárne rakety stredného doletu. Odporcovia vojny požadovali, aby obe strany, Sovietsky
        zväz aj Spojené štáty, obmedzili svoj nukleárny arzenál. V roku 1987 bola vytvorená Zmluva o jadrových silách
        stredného doletu (INF).Vzhľadom na nedostatok slobody prejavu a politickej transparentnosti v sovietskom bloku
        sa zmluvné podmienky mohli účinne zaručiť iba na demokratickom západe. Po studenej vojne a na začiatku vojny
        proti terorizmu zohrávali organizácie s komunistickými alebo radikálnymi ľavicovými väzbami naďalej hlavnú úlohu
        pri riadení amerického protivojnového hnutia.<a href="#_edn27" id="_ednref27">[27]</a></p>
      <p>Komunisti sa tiež snažili prevziať vedenie v Afroamerickom hnutí za občianske práva. Už koncom 20. rokov
        objavila komunistická Americká robotnícka strana veľký revolučný potenciál medzi černošskými Američanmi.<a
          href="#_edn28" id="_ednref28">[28]</a> Komunistická propagandistická príručka publikovaná roku 1935 a nazvaná
        „Černosi v Sovietskej Amerike“, navrhovala skombinovať rasovú revolúciu na Juhu, vrátane vytvorenia Čiernej
        republiky s celkovou proletárskou revolúciou.<a href="#_edn29" id="_ednref29">[29]</a></p>
      <p>Skupiny vnútri hnutia za občianske práva v Spojených štátoch v 60. rokoch 20. storočia získavali podporu od
        sovietskej i čínskej komunistickej strany. Extrémistické Revolučné akčné hnutie a&nbsp;maoistická Strana
        čiernych panterov boli podporované alebo priamo ovplyvňované ČKS. V lete roku 1965 bolo niekoľko amerických
        miest sužovaných rasovými nepokojmi. Keď Leonard Patterson vystúpil z&nbsp;Komunistickej strany USA, svedčil o
        tom, že povstanie a nepokoje medzi americkými černochmi mali silnú podporu americkej komunistickej strany. Spolu
        s Hallom absolvovali výcvik v Moskve.<a href="#_edn30" id="_ednref30">[30]</a></p>
      <p>Čierni panteri ohľadom svojej organizačnej štruktúry a&nbsp;ideologického programu brali ako svoj vzor ČKS a
        prebrali aj jej slogany ako „politická moc vychádza z hlavne pušky“ a „všetka moc patrí ľudu“. <em>Citáty Mao
          Ce-tunga</em> boli povinným čítaním všetkých ich členov. Rovnako ako čínski komunisti aj Čierni panteri
        žiadali násilnú revolúciu. Jeden z ich vodcov, Eldridge Cleaver, predpovedal v roku 1968 vlnu teroru, násilia a
        partizánskych vojen. Na mnohých černošských zhromaždeniach účastníci mávali <em>Citátmi Mao
          Ce-tunga,</em> napodobňujúc Červené gardy, ktoré robili to isté v Číne.<a href="#_edn31"
                                                                                    id="_ednref31">[31]</a></p>
      <p>Hnutie za občianske práva úspešne priviedlo do verejnej diskusie rasové vzťahy a pomohlo Američanom liečiť
        niektoré z najhlbších rozdelení národa mierovými prostriedkami, ako sú legislatíva, demonštrácie, bojkoty a
        vzdelávanie. Ľavicoví revolucionári však naďalej používajú rasový konflikt ako odrazový mostík pre svoju
        radikálnu agendu.<a href="#_edn32" id="_ednref32">[32]</a></p>
      <h2>6. Americkí marxisti</h2>
      <p>V 60. rokoch 20. storočia bola „revolúcia v uliciach“ západnej mládeže v plnom prúde. Jeden intelektuál však
        ich naivitu, úprimnosť ani idealizmus nezdieľal. „Keď skutočný radikál zistí, že mať dlhé vlasy spôsobí
        psychologické prekážky pre komunikáciu a organizáciu, ostrihá sa,“ povedal Saul Alinsky, radikálny aktivista,
        organizátor, autor, ktorý sa&nbsp; stal „para-komunistickým“ agitátorom s tým najzhubnejším vplyvom po celé
        desaťročia.</p>
      <p>Dôvod, prečo je termín „para-komunista“ najvýstižnejším označením Alinského, spočíva v tom, že na rozdiel od
        starej ľavice (politických ľavičiarov) z 30. rokov a novej ľavice (kultúrnych ľavičiarov) zo 60. rokov Alinsky
        odmietol vyjadriť príslušnosť ohľadom svojich politických ideálov. Alinsky razil názor, že na svete žijú tri
        skupiny ľudí: „tí, čo majú“, „tí, čo majú málo a chcú viac“ a „tí, čo nemajú“. Zároveň vyzýval „tých, čo
        nemajú“, aby všetkými prostriedkami povstali proti „tým, čo majú“ a chopili sa bohatstva a moci za účelom
        vytvoriť absolútne „rovnoprávnu“ spoločnosť a zničiť existujúci sociálny systém. Preto bol postkomunistickou
        ľavicou nazývaný „Leninom“ alebo „Sun-c’-om“.<a href="#_edn33" id="_ednref33">[33]</a></p>
      <p>Alinsky nielen chválil komunistických diktátorov ako Lenina a Fidela Castra, ale vyhlásil tiež svoju oddanosť
        diablovi. V úvode svojej knihy <em>Pravidlá pre radikálov</em>, ktorá bola uverejnená v roku 1971, v jednom z
        hesiel píše: „Nezabudnime aspoň na letmé uznanie pre úplne prvého radikála: zo všetkých našich legiend,
        mytológie a histórie (a kto vie, kde končí mytológia a začína história, alebo čo je čo), prvý radikál známy
        ľuďom, ktorý bojoval proti zriadeniu a robil to tak efektívne, že získal svoje vlastné kráľovstvo, bol Lucifer.“
      </p>
      <p>V knihe <em>Pravidlá pre radikálov</em>, Alinsky systematicky rozvinul svoje teórie a metódy organizovania
        komunít, ktoré používajú bezohľadné prostriedky na dosiahnutie cieľov a získanie moci. Medzi jeho pravidlá patrí
        napríklad toto: „Taktika, ktorá sa vlečie príliš dlho, sa stáva otravnou.“ „Vyvíjaj neustály nátlak.“ „Hrozba je
        obvykle desivejšia ako vec samotná.“ „Zosmiešňovanie je najmocnejšou ľudskou zbraňou.“ „Zvoľ si cieľ, zmraz ho,
        zosobni ho a polarizuj.“<a href="#_edn34" id="_ednref34">[34]</a></p>
      <p>Skutočná povaha Alinského zdanlivo neškodných pravidiel pre organizovanie komunít sa ukazuje pri ich použití v
        praxi. V priebehu vietnamskej vojny v roku 1972 mal George H. W. Bush, ktorý bol vtedy veľvyslancom Spojených
        štátov pri OSN, príhovor na Tulane University. Protivojnovo naladení študenti vyhľadali Alinského, aby im
        poradil, ako protestovať. Alinsky im povedal, že štandardný formát protestu pravdepodobne povedie k tomu, že ich
        jednoducho len vylúčia zo školy. Preto navrhol, aby si vzali obleky, ktoré používa Ku-Klux-klan, a aby všade
        tam, kde bude Bush obhajovať vojnu vo Vietname, povstali s transparentmi a vyhlásili, že „Ku-Klux-klan podporuje
        Busha“. Študenti to urobili a vo výsledku sa to stalo majstrovským príkladom klamlivej propagandy.<a
          href="#_edn35" id="_ednref35">[35]</a></p>
      <p>V roku 1964 Alinsky pripravil plán na zorganizovanie 2 500 aktivistov, ktorí mali obsadiť toalety na chicagskom
        medzinárodnom letisku O‘Hare, ktoré je jedným z najrušnejších letísk na svete, a tým si vynútiť prerušenie jeho
        prevádzky. O&nbsp;pláne sa dozvedeli chicagské úrady, ktoré boli nútené rokovať a k protestu nikdy nedošlo.<a
          href="#_edn36" id="_ednref36">[36]</a></p>
      <p>Za účelom donútenia Kodaku, hlavného zamestnávateľa v Rochesteri v štáte New York, aby zvýšil pomer čiernych
        zamestnancov voči bielym, prišiel Alinsky s podobnou taktikou. Jeho plán spočíval v tom, že na nadchádzajúce
        vystúpenie Rochester Philharmonic Orchestra, významnú kultúrnu udalosť v meste, Alinsky nakúpi stovky lístkov
        pre svojich aktivistov a pred vystúpením ich nakŕmi pečenými fazuľami. Naplnili by divadlo a zničili
        predstavenie zápachom z nadúvania. Tento plán sa neuskutočnil, ale Alinského taktika nakoniec prinútila Kodak
        splniť jeho požiadavky.</p>
      <p>Alinského knihy a rozhovory zanechávajú dojem charizmatického, ale nemilosrdného a vypočítavého jednotlivca.
        Jeho „organizovanie komunít“ bolo v skutočnosti formou postupnej revolúcie. Medzi &nbsp;Alinským a jeho
        predchodcami bolo niekoľko rozdielov. Po prvé, stúpenci starej aj novej ľavice boli prinajmenšom vo svojej
        rétorike idealistickejší, zatiaľ čo Alinsky vytrhol „revolúciu“ z jej idealistického pozlátka a vystavil ju ako
        neskrývaný mocenský boj. Keď viedol školenie „organizovanie komunít“, bežne sa pýtal tých, ktorých školil:
        „Prečo organizujeme?“ Niektorí odpovedali, že je to kvôli pomoci ostatným, ale Alinsky na to vždy zareval:
        „Chceme organizovať kvôli moci!“ Vo výučbovom manuále sa nasledovníci Alinského mali riadiť napríklad týmito
        heslami: „Nie sme cnostní preto, že nám nejde o moc. ... Sme skutoční zbabelci, pretože nám nejde o moc“, „moc
        je dobrá“ a „bezmocnosť je zlá“.<a href="#_edn37" id="_ednref37">[37]</a></p>
      <p>Po druhé, Alinsky nemal vysokú mienku o rebelujúcej mládeži 60. rokov, ktorá vystupovala proti vláde a
        spoločnosti verejne. Zdôrazňoval, že kedykoľvek je to možné, je potrebné vstúpiť dovnútra systému a čakať na
        príležitosti pre jeho rozvracanie zvnútra.</p>
      <p>Po tretie, Alinského konečným cieľom bolo rozvrátiť a ničiť, nie prospieť nejakej skupine. Preto bolo teda na
        vykonanie jeho plánu nevyhnutné skrývať skutočné úmysly využitím čiastkových alebo vykonštruovaných cieľov,
        ktoré boli zdanlivo zmysluplné alebo samy osebe neškodné, aby zmobilizoval veľké davy k činu. Keď si ľudia
        zvykli, že sú mobilizovaní, bolo relatívne ľahké ich mobilizovať, aby konali v mene radikálnejších cieľov.</p>
      <p>V <em>Pravidlách pre radikálov</em> Alinsky píše: „Každej revolučnej zmene musí predchádzať pasívny, súhlasný,
        nespochybniteľný postoj k zmene v masách našich ľudí. ... Pamätajte si: akonáhle zorganizujete ľudí okolo
        niečoho, na čom sa ľudia všeobecne zhodnú, ako napríklad znečistenie, potom sa organizovaní ľudia dajú do
        pohybu. Odtiaľ je už krátky a prirodzený krok k politickému znečisteniu, k znečisteniu Pentagonu. “</p>
      <p>Vodca organizácie Študenti pre demokratickú spoločnosť, ktorého Alinsky hlboko ovplyvnil, vystihol podstatu
        radikalizujúcich protestov: „Nikdy nejde o problém samotný, cieľom je vždy revolúcia.“ Radikálna ľavica bola po
        60. rokoch Alinským hlboko ovplyvnená a reakciu na akýkoľvek sociálny problém vždy premenila na nespokojnosť so
        všeobecným status quo, a táto nespokojnosť sa stávala odrazovým mostíkom pre dosahovanie revolučných cieľov.</p>
      <p>Po štvrté, Alinsky premenil politiku na partizánsku vojnu bez akýchkoľvek obmedzení. Pri vysvetľovaní svojej
        stratégie pre organizovanie komunít Alinsky svojim stúpencom povedal, že potrebujú zasiahnuť nepriateľove
        zmysly. V <em>Pravidlách pre radikál</em>ov píše: „Najprv oči: ak ste zorganizovali rozsiahlu, na širokých
        masách založenú ľudovú organizáciu, môžete ju viditeľne nechať pochodovať pred nepriateľom a otvorene ukázať
        svoju moc. Potom uši: ak je vaša organizácia malá, potom urobte to, čo urobil Gedeon: skryte svojich členov v
        temnote, ale vytvorte hluk a&nbsp;krik, ktoré poslucháča prinútia uveriť, že je vaša organizácia oveľa
        početnejšia ako v skutočnosti. A nakoniec nos: ak je vaša organizácia príliš malá, aby urobila hluk, potom
        miesto naplňte smradom.“</p>
      <p>Po piate: Alinsky za svojho pôsobenia v politike zdôrazňoval tie najšpinavšie aspekty ľudskej povahy, vrátane
        lenivosti, chamtivosti, závisti a nenávisti. Niekedy mohli účastníci jeho kampaní dosiahnuť drobných ziskov, ale
        vďaka nim sa stali ešte cynickejšími a bezočivejšími. Aby dosiahol svoj cieľ podvracania politického systému a
        spoločenského poriadku slobodných krajín, Alinsky s obľubou viedol svojich nasledovníkov k morálnemu úpadku. Z
        toho možno odvodiť, že ak by skutočne získal moc, nebude svojich bývalých kamarátov ľutovať, ani sa o nich
        starať.</p>
      <p>O niekoľko desaťročí neskôr dve prominentné osobnosti americkej politiky, ktoré boli hlboko ovplyvnené
        Alinským, pomohli začať tichú revolúciu, ktorá podlomila americkú civilizáciu, jej tradície a hodnoty. Súčasne s
        tým v Amerike od 70. rokov 20. storočia začali byť populárne protesty formou nekontrolovaných partizánskych
        vojen, ktoré Alinsky obhajuje, ako je vidieť v hnutí Occupy Wall Street, hnutí Antifa a ďalších.</p>
      <p>Treba poznamenať, že Alinsky vyjadril svoje uznanie „prvému zo všetkých radikálov“ – Luciferovi – nielen na
        úvodných stranách Pravidiel pre radikálov. V rozhovore s časopisom Playboy krátko pred svojou smrťou Alinsky
        uviedol, že až zomrie, bezvýhradne sa rozhodne „ísť do pekla“, a že tam začne organizovať „tých, čo nemajú“,
        pretože, ako hovorí, „to sú moji ľudia“.<a href="#_edn38" id="_ednref38">[38]</a></p>
      <h2>7. Dlhý ľavicový pochod inštitúciami</h2>
      <p>V tridsiatych rokoch 20. storočia prominentný taliansky komunista Antonio Gramsci, napísal, že ak chcú
        socialisti rozvrátiť západnú spoločnosť zvnútra, musia vybojovať „boj o pozíciu“, čo sa neskôr nazývalo „dlhý
        pochod inštitúciami“. Uvedomil si, že je ťažké ľudí podnietiť k začatiu revolúcie na zvrhnutie legitímnej vlády,
        keď ľudia stále majú vieru v nebesá. Preto sa komunisti museli spoliehať na veľké množstvo pešiakov, ktorí
        zdieľajú ich temný pohľad na morálku, vieru a tradície. Revolúcia proletariátu v&nbsp;tomto prípade musí začať
        rozvracaním náboženstva, morálky a civilizácie.</p>
      <p>Po nepokojoch v 60. rokoch začali rebeli, ktorí sa snažili o&nbsp;revolúciu, vstupovať do akademickej sféry.
        Získali tituly, stali sa učencami, profesormi, vládnymi úradníkmi a novinármi a vstúpili do hlavného prúdu
        spoločnosti, aby realizovali dlhý pochod inštitúciami. Takto infiltrovali a narušili inštitúcie západnej
        spoločnosti, ktoré hrajú rozhodujúcu úlohu pre udržanie morálky v spoločnosti. Tieto inštitúcie zahŕňajú cirkev,
        vládu, vzdelávací systém, legislatívne a súdne orgány, umelecký svet, médiá i mimovládne organizácie.</p>
      <p>Existuje mnoho zdanlivo legitímnych prostriedkov, pomocou ktorých bezohľadní ľudia alebo skupiny môžu zničiť
        slobodnú spoločnosť zvnútra. Aby bola demokracia účinná, musí byť ľud naklonený k občianskej cnosti a musí mať
        určitý morálny štandard. Spojené štáty boli od 60. rokov ako pacient s infekciou, ktorý nie je schopný
        identifikovať príčinu svojej choroby. Para-marxistické myšlienky prenikli hlboko do americkej spoločnosti a
        šíria sa prakticky nekontrolovateľne.</p>
      <p>Z mnohých predložených revolučných stratégií je jednou z najznámejších stratégia dvoch sociológov z
        Kolumbijskej univerzity, Clowarda a Pivenova v roku 1966 (ktorí boli tiež členmi Demokratických socialistov
        Ameriky). Jadrom konceptu tejto stratégie je nafukovanie verejného systému sociálneho zabezpečenia s cieľom
        privedenia vlády do bankrotu. Autori vyhlasovali, že keďže počet osôb oprávnených na sociálne dávky je oveľa
        vyšší ako počet osôb, ktoré skutočne dávky poberajú, štátnu pokladňu možno vyčerpať povzbudením ľudí, aby
        hromadne žiadali o dávky. Štátna vláda by tak bola nútená zakročiť s&nbsp;cieľom „záchrany“ a reformy systému
        tým, že by vláde poskytla ešte väčšiu kontrolu a smerovala k socializovanému systému.</p>
      <p>Národná organizácia pre sociálne práva (NWRO), ktorá bola aktívna od roku 1966 do roku 1975, sa usilovala o
        implementáciu tejto stratégie, potom čo prezident Lyndon B. Johnson spustil boj proti chudobe. Podľa štatistík
        sa v rokoch 1965 až 1974 počet rodín s jedným rodičom, ktoré dostávali dávky, zvýšil z 4,3 milióna na 10,8
        milióna, čo je viac ako dvojnásobok. V roku 1970 bolo 28 % ročného rozpočtu New Yorku vynaložené na výdavky na
        sociálne zabezpečenie. Od roku 1960 do roku 1970 vzrástol v štáte New York počet ľudí, ktorí dostávali dávky, z
        200 000 na 1,1 milióna. „Začiatkom 70. rokov na každé dve osoby, ktoré pracovali v&nbsp;súkromnej sfére,
        pripadla v priemere jedna osoba poberajúca sociálne dávky,“ napísal novinár Sol Stern v časopise City Journal. V
        roku 1975 mesto New York v&nbsp;podstate zbankrotovalo.<a href="#_edn39" id="_ednref39">[39]</a></p>
      <p>Príklad Cloward-Pivenovej stratégie v New Yorku možno považovať za implementáciu teórií Saula Alinského, z
        ktorých jedna znie: „donúť nepriateľa, aby konal podľa svojich vlastných pravidiel“ t. j. ak pravidlá hovoria,
        že každý je oprávnený na získanie dávok, potom nútenie „nepriateľa“ dodržať svoje vlastné pravidlá povedie k
        bankrotu bez zjavného nepriateľského konania zo strany radikálov.</p>
      <p>Cleon Skousen napísal vo svojej knihe <em>Naked Communist</em> (Nahý komunista), že jedným zo 45 cieľov
        komunistov je „zmocniť sa jednej alebo oboch politických strán v Spojených štátoch“. Komunistická strana videla,
        že by sa to dalo dosiahnuť pomocou malého počtu ľudí, keď budú organizovať vytvorenie „kríz“ a „revolúcií“,
        ktoré by sa dali využiť v prospech komunistickej strany. Lenin raz povedal, že odborové zväzy sú „pohyblivé pásy
        spájajúce komunistickú stranu s masami“<a href="#_edn40" id="_ednref40">[40]</a>. Komunisti zistili, že ak
        ovládajú odborové zväzy, ovládajú veľké množstvo hlasov. Ak ovládajú hlasovania, môžu prinútiť úradníkov a
        zákonodarcov k tomu, aby počúvali ich príkazy. Bežní pracovníci sú nútení vstúpiť do odborových zväzov, aby si
        zachovali svoje základné práva a záujmy, a tak sa stanú pešiakmi odborov. Rovnaký princíp funguje aj pri platení
        poplatkov za ochranu syndikátom organizovaného zločinu.</p>
      <p>Filmár a vedecký pracovník Loudon vysvetľuje, ako komunistické subjekty využívajú odbory a iné skupiny ako
        spojky na vykonávanie svojich postupov a zmocňujú sa demokratických krajín.</p>
      <p>Po prvé, zahraničný alebo miestny komunistický subjekt stanoví svoj program, napríklad posilňovanie pracovných
        právnych predpisov, implementáciu socialistickejších školských osnov alebo zmierňovanie obchodných sankcií voči
        Kube. Po druhé, komunisti a ich socialistickí spojenci prijímajú tieto politiky ako politiky odborov. Odbory
        potom vyvíjajú tlak na miestnu labouristickú stranu, socialistickú stranu alebo demokratickú stranu, aby prijali
        tieto odborové politiky ako svoje vlastné. „Keďže odbory v podstate kontrolujú tieto hlavné strany, proces často
        nie je taký zložitý,“ napísal Loudon v roku 2014. Keď sa komunistické politiky stanú odborovými politikami,
        stávajú sa politikami „hlavných“ politických strán. „Tento proces sa uskutočnil nespočetne krát po celom svete,“
        napísal Loudon.<a href="#_edn41" id="_ednref41">[41]</a></p>
      <p>Komunisti a tí, ktorí nevedomky konajú v ich prospech, sa pokúšajú všemožnými spôsobmi narušiť politický a
        spoločenský systém slobodných spoločností. Po desaťročiach plánovania a fungovania boli vlády a spoločnosti
        Spojených štátov a západných krajín silne narušené.</p>
      <h2>8. Politická korektnosť</h2>
      <p>Komunistické krajiny vždy praktizovali prísnu kontrolu reči a myšlienok. Od 80. rokov 20. storočia sa na Západe
        objavila ďalšia forma kontroly reči a myslenia. Táto myšlienková polícia pod zástavou „politickej korektnosti“
        šíri v médiách, v spoločnosti a vzdelávacom systéme slogany a masovú kritiku obmedzujúcu reč a myslenie. Hoci už
        mnohí pocítili nesprávnosť tejto kontroly, nepochopili jej ideologický pôvod.</p>
      <p>Frázy ako „politická korektnosť“ či „pokrok“ a „solidarita“ sú všetko slová, ktoré komunistické strany
        používajú už dlho. Ich zmyslom na povrchu je predísť užívaniu diskriminačného jazyka voči menšinám, ženám,
        zdravotne postihnutým a ďalším. Skrytým dôsledkom politickej korektnosti je zaradenie jednotlivcov do skupín
        podľa ich statusu obete. Tí, ktorí sú údajne najviac utláčaní, by mali byť najviac rešpektovaní a požívať
        najväčšej úcty. Bez ohľadu na individuálne správanie a talent je úsudok o človeku vytvorený výlučne na základe
        jeho identity, čo sa nazýva „politika identity“. Tento štýl myslenia je v Spojených štátoch a ďalších západných
        krajinách veľmi populárny.</p>
      <p>Tento druh klasifikácie je identický s&nbsp;tým, čo sa udialo v&nbsp;Číne, kde boli jednotlivci zaraďovaní do
        „piatich červených tried“ alebo „piatich čiernych tried“ podľa svojho bohatstva a spoločenskej triedy pred
        revolúciou. Čínska komunistická strana vyhladzovala a utláčala vlastníkov pôdy a kapitalistov kvôli ich „zlej“
        triednej príslušnosti, zaútočila na intelektuálov, ktorých označila za „páchnuce staré deviatky“ a spievala, že
        „chudobní sú najmúdrejší, šľachta je najhlúpejšia“.</p>
      <p>Zo spletitých historických dôvodov, vrátane sociálnych a individuálnych dôvodov, majú niektoré skupiny nižšie
        politické a sociálno-ekonomické postavenie, ktoré nemožno jednoducho vysvetľovať ako útlak. Ale politická
        korektnosť vytvára v mysliach ľudí umelé hranice: iba tí, ktorí prejavia súcit s určenými „obeťami“ a pohŕdajú
        „utláčateľmi“, môžu byť považovaní za morálnych, zatiaľ čo tí, ktorí nesúhlasia, sú obvinení z rasistických,
        sexistických, homofóbnych, antiislamských a podobných sklonov.</p>
      <p>Mnohé západné vlády a mimovládne organizácie pretláčalo politickú korektnosť, aby presadili ľavicovú agendu.
        V&nbsp;niektorých krajinách bola výrazne rozšírená právna definícia „nenávistných prejavov“ a&nbsp;v školách,
        médiách a internetových spoločnostiach sa presadzujú tresty za takýto prejav.<a href="#_edn42"
                                                                                        id="_ednref42">[42]</a> Tieto
        celkové obmedzenia slobody prejavu posúvajú demokratické spoločnosti smerom ku kontrole myšlienok vykonávaných
        komunistickými štátmi.</p>
      <p>&nbsp;</p>
      <p>Ľavica v súčasnosti zneužíva politickú korektnosť, aby druhým odoprela legitímny priestor pre vyjadrenie
        názoru. Toto sa stalo výraznejším po prezidentských voľbách v USA v roku 2016, keď sa ľavicové médiá,
        organizácie a akademici mobilizovali, aby oslabili a umlčali podporovateľov prezidenta Donalda Trumpa. Vo
        veľkých mestách vypukli protestné pochody a častejšie sa vyskytovalo porušovanie slobody prejavu. Univerzity,
        ktoré majú byť baštami slobodného myslenia a prejavu, sa stali centrami radikálnej indoktrinácie. Organizácie
        konajúce pod vlajkou odporovania „nenávistným prejavom“ označili bežné konzervatívne skupiny za „nenávistné
        skupiny“ a konzervatívni autori a vedci čelili vyhrážkam potom, ako boli pozvaní vystúpiť alebo zúčastniť sa
        rôznych podujatí.<a href="#_edn43" id="_ednref43">[43]</a></p>
      <p>V marci 2017 bol americký sociálny vedec Charles Murray pozvaný, aby prehovoril na Middlebury College vo
        Vermonte. Keď chcel prehovoriť, viac ako štyristo demonštrantov naplnilo miestnosť a ich krik a&nbsp;posmešky mu
        v&nbsp;prejave zabránili. Neskôr, keď odchádzal z areálu, bol spolu s profesorom, ktorý ho na vysokej škole
        sprevádzal, obkľúčený študentmi, ktorí do nich strkali. Profesora museli odviezť do nemocnice kvôli poraneniu
        krku.</p>
      <p>V septembri roku 2017 bolo na Kalifornskej univerzite v Berkeley konzervatívnemu autorovi Benovi Shapirovi
        zabránené v prednáške kvôli vyhrážkam extrémne ľavicovej extrémistickej skupiny Antifa, ktorá hrozila, že vyvolá
        násilný konflikt. Berkeleyská polícia bola v pohotovosti, vyslala tri policajné helikoptéry, náklady na
        bezpečnosť sa odhadovali na viac ako 600 tisíc dolárov.<a href="#_edn44" id="_ednref44">[44]</a> Je iróniou, že
        jednou z dôležitých udalostí, ktoré stáli na začiatku študentského hnutia v roku 1964, bol boj za slobodu
        prejavu práve na Berkeley. Nasledujúci mesiac, keď bolo naplánované vystúpenie Shapira na univerzite v Utahu, sa
        študentská skupina zaviazala túto akciu zrušiť. Reportér upozornil mladého vodcu skupiny, že bránenie Shapirovi
        v prejave nebude v súlade s Prvým dodatkom ústavy. Študent odpovedal: „Je mi to jedno. Myslím si, že to už teraz
        nie je relevantný dokument.“<a href="#_edn45" id="_ednref45">[45]</a></p>
      <p>V marci 2018 profesorke Amy Waxovej, z právnickej fakulty Pensylvánskej univerzity, odobrali &nbsp;niektoré
        vyučovacie hodiny, keď sa počas rozhovoru s profesorom z Brown University vyjadrila politicky nekorektný
        postreh. Wax povedala, že černošskí študenti sú „zriedka“ medzi najlepšími z ročníku.<a href="#_edn46"
                                                                                                id="_ednref46">[46]</a>
      </p>
      <p>Politická korektnosť a&nbsp;obmedzenia slobody prejavu propagované ľavicou nie sú zamýšľané na podporu zdravej
        diskusie o&nbsp;rôznych uhloch pohľadu. Sú to ideologické zbrane používaní tými, čo majú zlé úmysly. Politická
        korektnosť je „myšlienkovou políciou“ ducha komunizmu na&nbsp;potlačenie nesúhlasu a&nbsp;zahmlenie pravdy.</p>
      <h2>9. Socializmus v Európe</h2>
      <p>Celej Európe – nielen krajinám východnej Európy – vládne komunizmus. Nekomunistické krajiny v severnej, južnej
        a západnej Európe vedome či nevedome propagujú komunistické ideológie a politiky. Nie je prehnané povedať, že
        Európa je v „rukách nepriateľa“.</p>
      <p>Socialistická internacionála je najväčšou medzinárodnou politickou organizáciou na svete, ktorá pozostáva z
        viac ako 135 politických strán a organizácií. Táto organizácia vzišla z Druhej internacionály, ktorú založil
        Engels v roku 1889. Medzi raných socialistov patrili ľudia ako Karl Johann Kautsky a Eduard Bernstein, ktorí
        podporovali progresívnu reformu.&nbsp; V čase založenia Druhej internacionály existovalo po celom svete viac ako
        sto politických strán, ktoré boli založené na marxizme. 66 z nich boli vládnuce strany, ktoré sa vo svojich
        krajinách držali socializmu. Názov „Socialistická internacionála“ pochádza z roku 1951.</p>
      <p>V súčasnosti z Druhej internacionály vyrástli mnohé socialistické strany po celej Európe a mnohé z nich sa
        dokonca stali vládnucimi stranami.&nbsp;</p>
      <p>Strana európskych socialistov, založená v roku 1992, ktorá pôsobí v Európskom parlamente, je tiež spojená so
        Socialistickou internacionálou. Jej členmi sú sociálnodemokratické strany EÚ a okolitých krajín vrátane
        Spojeného kráľovstva. Jej členov možno nájsť vo väčšine popredných európskych organizácií vrátane Európskeho
        parlamentu, Európskej komisie a Európskej rady. Strana európskych socialistov má v súčasnosti tridsaťtri
        členských strán, ako aj dvanásť pridružených členov a dvanásť pozorovateľov, celkovo päťdesiatsedem politických
        strán z celej Európskej únie, Spojeného kráľovstva a Nórska. Jej hlavným cieľom je „formovať progresívnu
        európsku politiku“ a rozvíjať úzku spoluprácu medzi členskými stranami, parlamentnými skupinami a podobne. V
        podstate pracuje na dôraznom presadzovaní socialistických cieľov.</p>
      <p>Hlavné zásady britskej Labouristickej strany (Strany práce) sú založené na fabiánskom socializme. Ako bolo
        popísané skôr, fabiánsky socializmus je jednoducho len inou verziou marxizmu, ktorá zdôrazňuje využívanie metód
        pre uskutočnenie postupného prechodu od socializmu ku komunizmu. Takisto obhajuje vysoké dane, vysoké sociálne
        dávky a iné socialistické myšlienky. Labouristická strana sa v posledných desaťročiach mnohokrát stala vládnucou
        stranou Anglicka a vždy obhajovala fabiánske socialistické myšlienky.</p>
      <p>Britská komunistická strana a&nbsp;jej rôzne odnože sú tiež veľmi aktívne v&nbsp;pokusoch ovplyvňovať britskú
        politiku, a dokonca vydáva vlastné noviny Daily Worker (premenované v roku 1966 The Morning Star). Táto strana
        vznikla z&nbsp;Komunistickej strany Veľkej Británie, ktorá bola založená v roku 1920, a ktorej členovia boli na
        vrchole jej popularity volení do Dolnej snemovne. Na začiatku volieb v roku 2017 Britská komunistická strana
        náhle oznámila, že má v úmysle podporiť vedúceho ľavicového politika Labouristickej strany. Tento politik, ktorý
        viedol Labouristickú stranu od septembra 2015, strávil 40 rokov propagáciou socialistickej politicky, vrátane
        znárodnenia majetku a protivojnového úsilia. Keď sa ho reportér BBC spýtal na jeho názory na Karla Marxa, ocenil
        ho ako veľkého ekonóma a „fascinujúcu postavu a veľkého pozorovateľa, od ktorého sa toho môžeme veľa
        naučiť“.</p>
      <p>Švédska sociálnodemokratická strana, ktorá je vo Švédsku vládnucou stranou, je členom Socialistickej
        internacionály. Počas niekoľkých desaťročí svojej vlády presadzovala socialistické myšlienky rovnosti a
        blahobytu. Jeden z vodcov Francúzskej socialistickej strany bol v roku 2012 zvolený za prezidenta. Strana je
        tiež členom Socialistickej internacionály a Strany európskych socialistov. Taliansky komunistický veterán
        Antonio Gramsci nielenže v roku 1921 založil Komunistickú stranu Talianska, ale pôsobil tiež ako jej generálny
        tajomník. Až do 90. rokov bola Komunistická strana Talianska veľmi aktívna a dlhodobo si udržiavala pozíciu
        druhej najväčšej politickej strany v krajine. V roku 1991 bola strana premenovaná na Demokratickú stranu ľavice
        a teraz je súčasťou vládnej koalície. Ostatné európske krajiny ako Španielsko, Portugalsko a ďalšie, majú veľmi
        aktívne komunistické politické strany s významným vplyvom. Nemecko nie je výnimkou, je rodiskom Marxa a Engelsa
        a domovom vplyvnej Frankfurtskej školy, ďalšej podoby marxizmu.</p>
      <h2>10. Podľahnutie diablovým trikom</h2>
      <p>Všade, kde sa objaví komunizmus, je sprevádzaný násilím, klamstvami, vojnou, hladomorom a diktatúrou. Otázkou
        je, prečo toľko ľudí stále bezvýhradne pomáha tomuto diablovi šíriť jeho lži, dokonca sa stávajú jeho poslušnými
        a fanatickými nástrojmi?</p>
      <p>Americký sociológ Paul Hollander vo svojej knihe <em>Politickí pútnici (Political Pilgrims) </em>z roku 1981
        opísal príbehy mnohých mladých intelektuálov, ktorí sa zamilovali do komunizmu a odcestovali do Sovietskeho
        zväzu, Číny a Kuby. Zatiaľ čo na týchto miestach dochádzalo k hrozivému týraniu ľudí, týmto mladým „pútnikom“
        nič z toho nebolo ukázané a po návrate nadšene písali knihy, v ktorých oslavovali socialistický systém.<a
          href="#_edn47" id="_ednref47">[47]</a></p>
      <p>V Spojených štátoch komunizmus v rôznych časových obdobiach priťahoval ľudí z rôznych dôvodov. Mnohí z prvých
        vodcov Komunistickej strany USA boli prisťahovalci z Ruska a krajín východnej Európy. Mali nízke ekonomické
        postavenie a bolo pre nich ťažké splynúť s miestnou komunitou. Do strany tak vstúpili predovšetkým kvôli vplyvom
        zo svojej vlasti.</p>
      <p>Po veľkej hospodárskej kríze sa vplyv marxizmu na Západe dramaticky zväčšil a takmer celá západná intelektuálna
        trieda sa začala otáčať doľava. Mnoho intelektuálov navštívilo Sovietsky zväz a potom, čo sa vrátili, vystúpili
        s prejavmi a napísali knihy propagujúce komunistickú ideológiu. Patrilo medzi nich mnoho vplyvných mysliteľov,
        spisovateľov, umelcov a reportérov.</p>
      <p>Generácia „baby boomers“ vstúpila na vysoké školy v 60. rokoch, vyrastala v povojnovom nadbytku, no napriek
        tomu bola pomýlená falošnými komunistickými ideológiami, zaujala kontrakultúrne postoje, ktoré mali formu
        protivojnových protestov, feminizmu a podobne. Ďalšia generácia študentov sa už učila ľavicové texty priamo z
        učebníc, pretože ich učiteľmi boli „etablovaní radikáli“ – a tak sa nakoniec podarilo dosiahnuť komunistický
        „dlhý pochod inštitúciami“, čím začal cyklus jeho neustáleho šírenia a udržiavania.</p>
      <p>Riaditeľ FBI J. Edgar Hoover, ktorého funkčné obdobie trvalo 37 rokov, vo svojej knihe venovanej odhaleniu
        komunizmu nazvanej <em>Majstri klamu (Masters of Deceit)</em>, rozdelil komunistických aktivistov do piatich
        skupín: otvorení členovia strany (nástroje strany s&nbsp;členskou legitimáciou), podzemní členovia strany (tí,
        ktorí pôsobia ako utajení influenceri v prospech komunistickej agendy), spolucestujúci (nie sú členmi strany,
        ale často dopĺňajú jej prácu), oportunisti (tí, ktorí podporujú stranu z dôvodu vlastných záujmov)
        a&nbsp;oklamaní (nevinné obete, ktoré nevedia, že sú pod kontrolou komunistického myslenia).<a href="#_edn48"
                                                                                                       id="_ednref48">[48]</a> V
        skutočnosti existuje len veľmi málo extrémne zlých a tvrdohlavých komunistických aktivistov. Ide skôr o to, že
        väčšina ľudí, ktorých uvažovanie je ovládané komunistickým myslením, bola do toho jednoducho vtiahnutá.</p>
      <p>Americkí novinári John Sílas Reed a Edgar Snow zohrávali hlavnú úlohu pri propagácii komunistickej ideológie po
        celom svete. Reed, autor knihy <em>Desať dní, ktoré otriasli svetom (Ten Days That Shook the World</em>) je
        jedným z troch Američanov, ktorí sú pochovaní na cintoríne pri múre Kremľa, čo znamená, že on sám bol
        komunistickým aktivistom. Jeho opis októbrovej revolúcie nebol objektívnym popisom skutočných udalostí, ale skôr
        starostlivo vypracovanou politickou propagandou.</p>
      <p>Edgar Snow, autor knihy <em>Červená hviezda nad Čínou (Red Star Over China)</em> &nbsp;bol tzv. spolucestujúcim
        komunizmu, ktorého žiarivé vyobrazenie čelných predstaviteľov ČKS zanechalo hlboký dojem na mnohých Američanov.
        V roku 1936 odišiel do pevnosti ČKS v Šan-peji (severná&nbsp; provincie Ša-an-si) a robil rozhovory s Mao
        Ce-tungom o revolučných plánoch. ČKS použila Snowa ako propagandistický nástroj, ktorý medzinárodnému publiku
        prezentoval naratív komunistickej strany.</p>
      <p>Jurij Bezmenov, bývalý agent KGB, spomína na svoju skúsenosť s prijímaním zahraničných „priateľov“ v čase, keď
        ešte pracoval ako špión. Harmonogram návštev bol čiastočne organizovaný sovietskou Zahraničnou spravodajskou
        službou a všetky návštevy v kostoloch, školách, nemocniciach, materských školách, továrňach a ďalších miestach
        boli vopred dohodnuté. Ľudia prítomní na mieste boli komunisti alebo politicky preverení ľudia, ktorí
        absolvovali školenie, aby sa zabezpečilo, že budú hovoriť jednotným hlasom strany. V roku 1967 významný americký
        časopis Look, poslal novinárov do Sovietskeho zväzu, aby priblížili určitú udalosť. Bezmenov ohľadom článkov
        novinárov povedal: „Od prvej po poslednú stránku to bol balík lží: klišé propagandy, ktoré boli americkým
        čitateľom prezentované ako názory a&nbsp;závery amerických novinárov. Nič nemohlo byť ďalej od [pravdy].“
        Sovietska propaganda tak bola distribuovaná americkej verejnosti prostredníctvom amerického časopisu.</p>
      <p>Jurij Bezmenov uviedol, že mnoho novinárov, hercov a športových hviezd možno ospravedlniť za to, že boli pri
        návšteve Sovietskeho zväzu zaslepení určitými skutočnosťami, ale že správanie mnohých západných politikov bolo
        neodpustiteľné. Podľa Bezmenova vymýšľali lži a snažili sa spolupracovať so sovietskymi komunistami kvôli svojej
        povesti a zisku, a nazval ich morálne skorumpovanými.<a href="#_edn49" id="_ednref49">[49]</a></p>
      <p>V knihe <em>Stále môžete veriť komunistom... že sú komunistami<em> (You Can Still Trust the Communists… to Be
        Communists</em></em>) Schwartz analyzoval, prečo niektorí mladí muži z bohatých rodín začali mať radi
        komunizmus. Uviedol štyri dôvody: Prvý – rozčarovanie z kapitalizmu, druhý – viera v materialistickú filozofiu
        života, tretia – intelektuálna hrdosť a štvrtý – nenaplnené náboženské potreby. Intelektuálna hrdosť sa vzťahuje
        na skúsenosti mladých ľudí vo veku okolo 18 – 20 rokov, ktorí sa ľahko stávajú korisťou komunistickej propagandy
        kvôli svojmu neúplnému porozumeniu dejinám, odporu voči autoritárstvu a&nbsp;rozčarovaniu z tradícií
        a&nbsp;národného dedičstva. Nenaplnené náboženské potreby sa týkajú skutočnosti, že každý človek má v sebe
        určitý druh duchovného impulzu, ktorý vedie k potrebe prekročenia seba samého či transcendencie. Avšak ateizmus
        a teória evolúcie, ktoré im boli v rámci vzdelávania vštepené, im znemožnili, aby dosiahli uspokojenie pomocou
        tradičných náboženstiev. Komunistický sen o oslobodení ľudstva využíva túto skrytú ľudskú potrebu a slúži im ako
        náhrada náboženstva.<a href="#_edn50" id="_ednref50">[50]</a></p>
      <p>Tendenciu byť oklamaní radikálnymi ideológiami majú tiež intelektuáli. Tento jav pritiahol aj pozornosť vedcov.
        Vo svojej knihe <em>Ópium intelektuálov (The Opium of the Intellectuals)</em> francúzsky filozof a sociológ
        Raymond Aron poukazuje na to, že intelektuáli 20. storočia na jednej strane tvrdo kritizovali tradičný politický
        systém, ale na strane druhej veľkoryso tolerovali alebo dokonca zatvárajú oči pred diktatúrou a zabíjaním v
        komunistických štátoch. Ľavicových intelektuálov, ktorí premenili svoju ideológiu na sekulárne náboženstvo,
        vníma ako pokryteckých, svojvoľných a&nbsp;fanatických ľudí. Vo svojej knihe <em>Intelektuáli: Od Marxa a
          Tolstého po Sartra a Chomského (Intellectuals: From Marx and Tolstoy to Sartre and Chomsky)</em> britský
        historik Paul Johnson skúmal život a radikálne politické názory Rousseaua a desiatky ďalších intelektuálov,
        ktorí ho nasledovali. Zistil, že zdieľajú dve fatálne slabosti – aroganciu a egocentrizmus.<a href="#_edn51"
                                                                                                      id="_ednref51">[51]</a>
      </p>
      <p>Komunizmus od 60. rokov významne prenikol do amerického vzdelávania. Mnoho mladých ľudí – ktorí sú bombardovaní
        ľavicovými médiami a dostávajú zjednodušené vzdelanie – navyše prepadlo televízii, počítačovým hrám, internetu a
        sociálnym médiám. Menia sa na „snehové vločky“, ľudí, ktorým chýbajú vedomosti, všeobecný rozhľad, zmysel pre
        zodpovednosť, povedomie o histórii a schopnosť vyrovnať sa s problémami. Sú naočkovaní komunistickou ideológiou
        alebo jej odnožami, ktoré do nich vštepovala generácia rodičov, a tak používajú skreslený rámec na hodnotenie
        nových skutočností, ktoré vidia a počujú. To znamená, že lži komunizmu okolo nich vytvorili vrstvu, ktorá im
        zabraňuje v pravdivom videní reality.</p>
      <p>Duch komunizmu využíva negatívne ale aj pozitívne ľudské emócie, aby vlákal ľudí do svojej pasce.
        Najtragickejšie zo všetkého na tom je to, že komunistická ideológia apeluje okrem ľudskej ignorancie, sebectva a
        chamtivosti aj na úprimnú túžbu pravých veriacich po idealizme, altruizme a sebaobetovaní.</p>
      <p>Fakt, že množstvo ľudí mohlo byť zlákaných klamstvom socializmu a komunizmu, je dôsledkom toho, že ľudstvo
        opustilo duchovnú vieru a uvoľnilo svoje morálne štandardy. Iba pomocou spravodlivej viery a morálnych hodnôt sa
        môže ľudstvo ochrániť pred démonickou manipuláciou zo strany ducha komunizmu.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><a href="#_ednref1" id="_edn1">[1]</a> Joseph (Jake) Klein, „An Interview With Trevor Loudon“ („Rozhovor
        s&nbsp;Trevorom Loudonom“), Capital Research Center, 24. februára 2017, zo 16. apríla 2020, &nbsp;<a
          href="https://capitalresearch.org/article/an-interview-with-trevor-loudon/"></a><a
          href="https://capitalresearch.org/article/an-interview-with-trevor-loudon/">https://capitalresearch.org/article/an-interview-with-trevor-loudon/</a>.
      </p>
      <p><a href="#_ednref2" id="_edn2">[2]</a> Karl Marx and Friedrich Engels,&nbsp;<em>Manifesto of the Communist
        Party</em>&nbsp;(Manifest Komunistickej strany), in <em>Marx &amp; Engels Selected Works,</em> vol. 1, trans.
        Samuel Moore, ed. Andy Blunden (Moscow: Progress Publishers, 1969), Marxists Internet Archive, zo 17. apríla
        2020,&nbsp;<a href="https://www.marxists.org/archive/marx/works/1848/communist-manifesto/ch04.htm"></a><a
          href="https://www.marxists.org/archive/marx/works/1848/communist-manifesto/ch04.htm">https://www.marxists.org/archive/marx/works/1848/communist-manifesto/ch04.htm</a>.
      </p>
      <p><a href="#_ednref3" id="_edn3">[3]</a> „Our History“, („Naša história“) Fabian Society (Fabiánska spoločnosť),
        zo 6. marca 2020, <a href="https://fabians.org.uk/about-us/our-history."></a><a
          href="https://fabians.org.uk/about-us/our-history.">https://fabians.org.uk/about-us/our-history.</a></p>
      <p><a href="#_ednref4" id="_edn4">[4]</a> Mary Agnes Hamilton,&nbsp;<em>Sidney and Beatrice Webb: A&nbsp;Study in
        Contemporary Biography</em>&nbsp; (London: Sampson Low, Marston &amp; Co., 1932). &nbsp;<a
        href="https://archive.org/stream/in.ernet.dli.2015.81184/2015.81184.Sidney-And-Beatrice-Webb_djvu.txt"></a><a
        href="https://archive.org/stream/in.ernet.dli.2015.81184/2015.81184.Sidney-And-Beatrice-Webb_djvu.txt">https://archive.org/stream/in.ernet.dli.2015.81184/2015.81184.Sidney-And-Beatrice-Webb_djvu.txt</a>
      </p>
      <p><a href="#_ednref5" id="_edn5">[5]</a> Vladimir I. Lenin, „<em>Left-Wing“ Communism: an Infantile
        Disorder&nbsp;</em>(„Detská nemoc „ľavičiarstva“ v&nbsp;komunizme“), (Moscow: Foreign Languages Publishing
        House, 1952), 47.</p>
      <p><a href="#_ednref6" id="_edn6">[6]</a> Bernard Shaw,&nbsp;<em>The Intelligent Woman’s Guide to Socialism and
        Capitalism&nbsp;</em>(„Príručka inteligentnej ženy k socializmu a&nbsp;kapitalizmu“) (New York: Brentanos
        Publishers, 1928), 470.</p>
      <p><a href="#_ednref7" id="_edn7">[7]</a> US Congress, House, Committee on Un-American Activities, <em>Communist
        Legal Subversion: The Role of the Communist Lawyer: Report,</em> 86th Cong., 1st sess., 16. februára 1959.</p>
      <p><a href="#_ednref8" id="_edn8">[8]</a> John Earl Haynes and Harvey Klehr, <em>Venona: Decoding Soviet Espionage
        in America</em> („Dekódovanie sovietskej špionáže v&nbsp;Amerike“) (New Haven: Yale University Press, 1999),
        138–145.</p>
      <p><a href="#_ednref9" id="_edn9">[9]</a> M. Stanton Evans a&nbsp;Herbert Romerstein,&nbsp;<em>Stalin’s Secret
        Agents</em>:&nbsp;<em>The Subversion of Roosevelt’s Government</em>&nbsp;(„Stalinovi tajní agenti: Podvracanie
        Rooseveltovej vlády“) (New York: Threshold Editions, 2012).</p>
      <p><a href="#_ednref10" id="_edn10">[10]</a> Whittaker Chambers, <em>Witness</em> (New York: Random House, 1952).
      </p>
      <p><a href="#_ednref11" id="_edn11">[11]</a> Thomas Schuman (Yuri Bezmenov),&nbsp;<em>Love Letter to
        America</em>&nbsp;(„Milostný list pre Ameriku“) (Los Angeles: Maxims Books, 1984 ), s. 21–46.</p>
      <p><a href="#_ednref12" id="_edn12">[12]</a> Ion Mihai Pacepa, Ronald J. Rychlak,&nbsp;
        <em>Disinformation</em><em>:</em>&nbsp;<em>Former Spy Chief Reveals Secret Strategies for Undermining Freedom,
          Attacking Religion, and Promoting Terrorism</em> (Dezinformácie: Bývalý špión odhalil tajné stratégie týkajúce
        sa podkopávania slobody, útoku na náboženstvo a podpory terorizmu), (Washington, DC: WND Books Inc., 2013).</p>
      <p><a href="#_ednref13" id="_edn13">[13]</a> Bureau of the Census, <em>Historical Statistics of the United States:
        Colonial Times to 1970, Part 2,</em> („Úrad sčítania ľudu, Historická štatistika Spojených štátov: Colonial
        Times do roku 1970, 2. časť“), zo 16. apríla 2020, <a
          href="https://www.census.gov/history/pdf/1930-39unemployment.pdf"></a><a
          href="https://www.census.gov/history/pdf/1930-39unemployment.pdf">https://www.census.gov/history/pdf/1930-39unemployment.pdf</a>.
      </p>
      <p><a href="#_ednref14" id="_edn14">[14]</a> Dinesh D’Souza,&nbsp;<em>The Big Lie: Exposing the Nazi Roots of the
        American Left&nbsp;</em>(„Veľká lož: Odhalenie nacistických koreňov americkej ľavice“) (Washington, DC : Regnery
        Publishing, 2017).</p>
      <p><a href="#_ednref15" id="_edn15">[15]</a> Jim Powell,&nbsp;<em>FDR’s Folly: How Roosevelt and His New Deal
        Prolonged the Great Depression</em>&nbsp;(„Pochabosť FDR: Ako Roosevelt a&nbsp;jeho Nová dohoda predĺžili Veľkú
        hospodársku krízu“) (New York: Crown Forum, 2003).</p>
      <p><a href="#_ednref16" id="_edn16">[16]</a> Milton Friedman, as quoted in Powell, <em>FDR’s Folly,</em> back
        cover. (ako je uvedené v Powell, FDR's Folly, zadná strana obálky).</p>
      <p><a href="#_ednref17" id="_edn17">[17]</a> <a href="https://www.aei.org/scholar/nicholas-eberstadt/">Nicholas
        Eberstadt</a>, „The Great Society at 50: What LBJ wrought“, <em>American Enterprise Institute,</em> 9. mája
        2014, zo 16. apríla 2020, <a href="https://www.aei.org/articles/the-great-society-at-50"></a><a
          href="https://www.aei.org/articles/the-great-society-at-50">https://www.aei.org/articles/the-great-society-at-50</a>.
      </p>
      <p><a href="#_ednref18" id="_edn18">[18]</a> Elmer T. Peterson, „This is the Hard Core of Freedom“ („Toto je tvrdé
        jadro slobody“) (The Daily Oklahoman, 9. decembra 1951).</p>
      <p><a href="#_ednref19" id="_edn19">[19]</a> William S. Lind, <em>‘Political Correctness:’ A Short History of an
        Ideology</em> (Washington, DC: Free Congress Foundation, 2004), 4–5 („‚Politická korektnosť:‘ Krátka história
        ideológie.“)</p>
      <p><a href="#_ednref20" id="_edn20">[20]</a> Na rovnakom mieste 10.</p>
      <p><a href="#_ednref21" id="_edn21">[21]</a> Lin Biao, “Defeat U.S. Imperialism and Its Lackeys by People’s War,”
        in <em>Long Live the Victory of People’s War! („Porazte americký imperializmus a jeho nedostatky v ľudovej
          vojne“</em><em> z</em><em> Nech žije víťazstvo ľudovej vojny!) </em>(Peking: Foreign Language Press, 1965).
      </p>
      <p><a href="#_ednref22" id="_edn22">[22]</a> Mikhail Suslov, „The Defense of Peace and the Struggle Against the
        Warmongers“ („Obrana mieru a&nbsp;boj proti vojnovým štváčom“), (New Century Publishers, February 1950).
        Marxists Internet Archive, zo 7. apríla 2020, <a
          href="https://www.marxists.org/archive/suslov/1949/11/x01.htm"></a><a
          href="https://www.marxists.org/archive/suslov/1949/11/x01.htm">https://www.marxists.org/archive/suslov/1949/11/x01.htm</a>.
      </p>
      <p><a href="#_ednref23" id="_edn23">[23]</a> Vladimir Bukovsky „The Peace Movement &amp; the Soviet Union“
        („Mierové hnutie a&nbsp;Sovietsky zväz“), (Commentary Magazine, 1982). zo 17.apríla 2020, &nbsp;<a
          href="https://www.commentarymagazine.com/articles/the-peace-movement-the-soviet-union/"></a><a
          href="https://www.commentarymagazine.com/articles/the-peace-movement-the-soviet-union/">https://www.commentarymagazine.com/articles/the-peace-movement-the-soviet-union/</a>
      </p>
      <p><a href="#_ednref24" id="_edn24">[24]</a> US Congress, <em>Congressional Record: Proceedings and
        Debates,</em> 88th Cong., 1st sess., Vol. 109, Part 1, January 9, 1963–January 30, 1963.</p>
      <p><a href="#_ednref25" id="_edn25">[25]</a> Stanislav Lunev and Ira Winkler,&nbsp;<em>Through the Eyes of the
        Enemy: The Autobiography of Stanislav Lunev</em>&nbsp;(:Očami nepriateľa: Autobiografia Stanislava Luneva:),
        (Washington D.C.: Regnery Publishing, 1998),</p>
      <p><a href="#_ednref26" id="_edn26">[26]</a> Ronald Radosh, as quoted in Robert Chandler, <em>Shadow World:
        Resurgent Russia, the Global New Left, and Radical Islam</em> („Svet tieňov: Obnovujúce sa Rusko, globálna nová
        ľavica a radikálny islam“) (Washington, DC.: Regnery Publishing, 2008), 389.</p>
      <p><a href="#_ednref27" id="_edn27">[27]</a> „AIM Report: Communists Run Anti-War Movement“ („Správa AIM:
        Komunisti rozbiehajú protivojnové hnutie“),<em>&nbsp;Accuracy in Media&nbsp;</em>(19.&nbsp;februára 2003),&nbsp;
        <a href="https://www.aim.org/aim-report/aim-report-communists-run-anti-war-movement/"></a><a
          href="https://www.aim.org/aim-report/aim-report-communists-run-anti-war-movement/">https://www.aim.org/aim-report/aim-report-communists-run-anti-war-movement/</a>.
      </p>
      <p><a href="#_ednref28" id="_edn28">[28]</a> John Pepper (Joseph Pogani),&nbsp;<em>American Negro
        Problems</em>&nbsp;(„Problémy amerických černochov“) (New York: Workers Library Publishers, 1928), Marxistický
        internetový archív, zo 17. apríla 2020, <a
          href="https://www.marxistsfr.org/history/usa/parties/cpusa/1928/nomonth/0000-pepper-negroproblems.pdf."></a><a
          href="https://www.marxistsfr.org/history/usa/parties/cpusa/1928/nomonth/0000-pepper-negroproblems.pdf.">https://www.marxistsfr.org/history/usa/parties/cpusa/1928/nomonth/0000-pepper-negroproblems.pdf.</a>
      </p>
      <p><a href="#_ednref29" id="_edn29">[29]</a> James W. Ford and James Allen,&nbsp;<em>The Negroes in a&nbsp;Soviet
        America</em>&nbsp;(„Černosi v&nbsp;Sovietskej Amerike“) (New York: Workers Library Publishers, 1934), s. 24–30.
      </p>
      <p><a href="#_ednref30" id="_edn30">[30]</a> Leonard Patterson, “I Trained in Moscow for Black Revolution” („Ako
        som sa v&nbsp;Moskve cvičil pre černošskú revolúciu“), Speakers Bureau of the John Birch Society, YouTube video,
        publikoval Swamp Yankee 20. augusta 2011 <a href="https://www.youtube.com/watch?v=GuXQjk4zhZs"></a><a
          href="https://www.youtube.com/watch?v=GuXQjk4zhZs">https://www.youtube.com/watch?v=GuXQjk4zhZs</a>.</p>
      <p><a href="#_ednref31" id="_edn31">[31]</a> G. Louis Heath, ed.,&nbsp;<em>Off the Pigs! The History and
        Literature of the Black Panther Party</em> („Preč s&nbsp;prascami! História a&nbsp;literatúra strany Čiernych
        panterov“)<em>&nbsp;</em>(Metuchen, NJ: Scarecrow Press, 1976), 61., s. 61.</p>
      <p><a href="#_ednref32" id="_edn32">[32]</a> Thurston Powers, „How Black Lives Matter Is Bringing Back Traditional
        Marxism“ („Ako Black Lives Matter oživuje tradičný marxizmus“),&nbsp;<em>The Federalist</em>, 28. septembra
        2016,&nbsp;<a
          href="http://thefederalist.com/2016/09/28/black-lives-matter-bringing-back-traditional-marxism/"></a><a
          href="http://thefederalist.com/2016/09/28/black-lives-matter-bringing-back-traditional-marxism/">http://thefederalist.com/2016/09/28/black-lives-matter-bringing-back-traditional-marxism/</a>.
      </p>
      <p><a href="#_ednref33" id="_edn33">[33]</a> David Horowitz,&nbsp;<em>Barack Obama’s Rules for Revolution: The
        Alinsky Model</em>&nbsp;(„Pravidlá pre revolúciu Baracka Obamu: Alinského model“) (Sherman Oaks, CA: David
        Horowitz Freedom Center, 2009), s. 6, 16.&nbsp;</p>
      <p><a href="#_ednref34" id="_edn34">[34]</a> Saul Alinsky, „<em>Rules for Radicals: A&nbsp;Practical Primer for
        Realistic Radicals</em>&nbsp;(„Pravidlá pre radikálov: Praktická učebnica pre realistických radikálov“) (New
        York: Vintage Books, 1971), 125–164.</p>
      <p><a href="#_ednref35" id="_edn35">[35]</a> Sanford D. Horwitt,&nbsp;<em>Let Them Call Me Rebel (Nech ma volajú
        rebelom): Saul Alinsky, His Life and Legacy</em> („Saul Alinsky, jeho život a&nbsp;odkaz“) (New York: Alfred A.
        Knopf, Inc., 1989), xv–xvi.</p>
      <p><a href="#_ednref36" id="_edn36">[36]</a> Eric Norden „Playboy Interview with Saul Alinsky: A Candid
        Conversation with the Feisty Radical Organizer“ („Rozhovor pre Playboy zo Saulom Alinským: Otvorený rozhovor s
        bojachtivým radikálnym organizátorom“), New English Review, <a
          href="http://www.newenglishreview.org/DL_Adams/Playboy_Interview_with_Saul_Alinsky/"></a><a
          href="http://www.newenglishreview.org/DL_Adams/Playboy_Interview_with_Saul_Alinsky/.">http://www.newenglishreview.org/DL_Adams/Playboy_Interview_with_Saul_Alinsky/.</a>&nbsp;
      </p>
      <p><a href="#_ednref37" id="_edn37">[37]</a> Ryan Lizza, „The Agitator,“ („Agitátor“), <em>The New Republic („Nová
        republika“),</em> 19. marca 2007.&nbsp;<a
        href="https://newrepublic.com/article/61068/the-agitator-barack-obamas-unlikely-political-education"></a><a
        href="https://newrepublic.com/article/61068/the-agitator-barack-obamas-unlikely-political-education">https://newrepublic.com/article/61068/the-agitator-barack-obamas-unlikely-political-education</a>
      </p>
      <p><a href="#_ednref38" id="_edn38">[38]</a> Norden, „Playboy Interview.“</p>
      <p><a href="#_ednref39" id="_edn39">[39]</a> David Horowitz and Richard Poe, <em>The Shadow Party: How George
        Soros, Hillary Clinton, and Sixties Radicals Seized Control of the Democratic Party („Tieňová strana: Ako sa
        George Soros, Hillary Clintonová a šesťdesiati radikáli zmocnili vedenia demokratickej strany“) </em>(Nashville,
        Tennessee: Nelson Current, 2006), 110–114.</p>
      <p><a href="#_ednref40" id="_edn40">[40]</a> V. I. Lenin, „Draft Theses on the Role and Functions of The Trade
        Unions Under the New Economic Policy“ („Stručné tézy o&nbsp;roli a&nbsp;funkciách odborových zväzov v&nbsp;rámci
        novej hospodárskej&nbsp; politiky“) v <em>Lenin Collected Works, vol. 42</em> (Moscow: Progress Publishers,
        1971), 374–386, Marxists Internet Archive, zo 17. apríla 2020 ,&nbsp;<a
          href="https://www.marxists.org/archive/lenin/works/1921/dec/30b.htm"></a><a
          href="https://www.marxists.org/archive/lenin/works/1921/dec/30b.htm">https://www.marxists.org/archive/lenin/works/1921/dec/30b.htm</a>.
      </p>
      <p><a href="#_ednref41" id="_edn41">[41]</a> Trevor Loudon, „Communism/Socialism: The Enemies Within,“ <em>The
        Schwarz Report,</em> vol. 54, no. 7, July 2014, <a
        href="http://www.schwarzreport.org/uploads/schwarz-report-pdf/schwarz-report-2014-07.pdf"></a><a
        href="http://www.schwarzreport.org/uploads/schwarz-report-pdf/schwarz-report-2014-07.pdf">http://www.schwarzreport.org/uploads/schwarz-report-pdf/schwarz-report-2014-07.pdf</a>.
      </p>
      <p><a href="#_ednref42" id="_edn42">[42]</a> Nathan Pinkoski. „Jordan Peterson Is a Fulcrum for Right and Left’s
        Switch on Free Expression, („Jordan Peterson je opora pre obrat slobodného prejavu vpravo alebo vľavo“) The
        Federalist. 2.&nbsp;februára 2018 2018.&nbsp;<a
          href="http://thefederalist.com/2018/02/02/jordan-peterson-marks-fulcrum-right-lefts-side-switch-free-expression/"></a><a
          href="http://thefederalist.com/2018/02/02/jordan-peterson-marks-fulcrum-right-lefts-side-switch-free-expression/">http://thefederalist.com/2018/02/02/jordan-peterson-marks-fulcrum-right-lefts-side-switch-free-expression/</a>&nbsp;
      </p>
      <p><a href="#_ednref43" id="_edn43">[43]</a> Stanley Kurtz, „Campus Chaos: Daily Shout-Downs for a Week,“ <em>National
        Review</em><em>,</em> 12. októbra 2017, <a
        href="https://www.nationalreview.com/corner/campus-chaos-daily-shout-downs-week-free-speech-charles-murray"></a><a
        href="https://www.nationalreview.com/corner/campus-chaos-daily-shout-downs-week-free-speech-charles-murray">https://www.nationalreview.com/corner/campus-chaos-daily-shout-downs-week-free-speech-charles-murray</a>.
      </p>
      <p><a href="#_ednref44" id="_edn44">[44]</a> Andrew O’Reilly, „Antifa protests mean high security costs for
        Berkeley Free Speech Week, but who’s paying the bill?“ („Protesty hnutia Antifa vyžadujú vysoké náklady na
        bezpečnosť počas Berkeley Free Speech Week, ale kto platí účet?“) Fox News, 15. septembra 2017.&nbsp;<a
          href="http://www.foxnews.com/us/2017/09/15/antifa-protests-mean-high-security-costs-for-berkeley-free-speech-week-but-whos-paying-bill.html."></a><a
          href="http://www.foxnews.com/us/2017/09/15/antifa-protests-mean-high-security-costs-for-berkeley-free-speech-week-but-whos-paying-bill.html">http://www.foxnews.com/us/2017/09/15/antifa-protests-mean-high-security-costs-for-berkeley-free-speech-week-but-whos-paying-bill.html</a>
      </p>
      <p><a href="#_ednref45" id="_edn45">[45]</a> „Outspoken Conservative Ben Shapiro Says Political Correctness Breeds
        Insanity,“ („Otvorený konzervatívec Ben Shapiro hovorí, že politická korektnosť rodí šialenstvo“), ABC News, 20.
        októbra 2017, <a
          href="https://www.youtube.com/watch?time_continue=3&amp;v=vj5JXrpwsZs&amp;feature=emb_logo.">https://www.youtube.com/watch?time_continue=3&amp;v=vj5JXrpwsZs&amp;feature=emb_logo.</a>
      </p>
      <p><a href="#_ednref46" id="_edn46">[46]</a> Jessica Schladebeck&nbsp; „Penn Law professor loses teaching Duties
        for saying black students ‘rarely’ earn top marks“ („Profesor práv na univerzite v&nbsp;Pensylvánii prichádza
        o&nbsp;miesto kvôli prehláseniu, že černošskí študenti len zriedka dosahujú najlepšie známky“), New York Daily
        News, 15. marca 2018,&nbsp;<a
          href="http://www.nydailynews.com/news/national/law-professor-upenn-loses-teaching-duties-article-1.3876057."></a><a
          href="http://www.nydailynews.com/news/national/law-professor-upenn-loses-teaching-duties-article-1.3876057.">http://www.nydailynews.com/news/national/law-professor-upenn-loses-teaching-duties-article-1.3876057.</a>&nbsp;
      </p>
      <p><a href="#_ednref47" id="_edn47">[47]</a> Paul Hollander,&nbsp;<em>Political Pilgrims: Travels of Western
        Intellectuals to the Soviet Union, China, and Cuba </em>&nbsp;(„Politickí pútnici: Cesty západných intelektuálov
        do Sovietskeho zväzu, Číny a na Kubu“) (New York: Oxford University Press, 1981).</p>
      <p><a href="#_ednref48" id="_edn48">[48]</a> J. Edgar Hoover,&nbsp;<em>Masters of Deceit: The Story of Communism
        in America and How to Fight It </em>&nbsp;(„Majstri klamu: Príbeh komunizmu v Amerike a ako proti nemu bojovať“)
        (New York: Henry Holt and Company, 1958), s. 81-96.</p>
      <p><a href="#_ednref49" id="_edn49">[49]</a> Tomas Schuman (Yuri Bezmenov),&nbsp;<em>No „Novoste“ Is Good
        News</em>&nbsp;(Los Angeles: Almanac, 1985), s. 65–75.</p>
      <p><a href="#_ednref50" id="_edn50">[50]</a> Fred Schwartz a&nbsp;David Noebel,&nbsp;<em>You Can Still Trust the
        Communists…to Be Communists (Socialists and Progressives too)</em>&nbsp;(„<em>Stále môžete veriť komunistom...
        že sú komunistami </em>(socialisti a&nbsp;progresívci tiež)“), (Manitou Springs, Colo.: Christian Anti-Communism
        Crusade), (Kresťanská anti-komunistická kampaň) 2010, s. 44–52.</p>
      <p><a href="#_ednref51" id="_edn51">[51]</a> Paul Johnson,&nbsp;<em>Intellectuals: From Marx and Tolstoy to Sartre
        and Chomsky</em>&nbsp;(Intelektuáli: Od Marxa a&nbsp;Tolstého po&nbsp;Sartra a&nbsp;Chomského), (New York:
        Harper Perennial 2007), s. 225.</p>
      <Link to={`/kapitola_06`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


